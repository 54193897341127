import React, {
    useCallback,
    useEffect,
    useState,
} from "react";
import TableWithSearchAndPagination from "../../../components/tableWithSearchAndPaginationAndFilter";
import Button from "../../../components/button";
import WithHeaderSidebar from "../../../layout/withHeaderSidebar";
import WithAuth from "../../../layout/withAuth";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import Dropdown from "../../../components/dropdown";
import TextInput from "../../../components/textInput";
import { connect } from "react-redux";
import { handleDrawer } from "../../../actions/userActions";
import { getDiseaseReport } from "../../../services/reports/diseaseReport";
import { getDisease } from "../../../services/disease";

const headings = [
    {
        label: "Disease Name",
        width: "60%",
        align: "left",
    },
    {
        label: "Total Cases",
        width: "40%",
        align: "left",
    },
];

const dataToShow = [
    {
        key: "diseaseName",
        align: "left",
    },
    {
        key: "totalCases",
        align: "left",
    },
];

const Report = ({ handleDrawer }) => {
    const [totalData, setTotalData] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [callbackAttributes, setCallbackAttributes] = useState({
        perPage: "25",
        selectedPage: "0",
        startDate: null,
        endDate: null,
        gender: null,
        startAge: null,
        endAge: null,
        diseaseId: null,
        searchSend: "",
    });
    const [diseaseReport, setDiseaseReport] = useState([]);
    const [disease, setDisease] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)

    const displayDiseaseList = useCallback(() => {
        setDiseaseReport([]);
        const { searchSend, selectedPage, perPage, diseaseId, gender, startAge, endAge, startDate, endDate } = callbackAttributes;
        let data = `page=${selectedPage}&size=${perPage}`;

        // Conditionally append values to the data string if they exist
        if (diseaseId) {
            data += `&diseaseId=${diseaseId}`;
        }

        if (startDate) {
            data += `&startDate=${startDate}`;
        }

        if (endDate) {
            data += `&endDate=${endDate}`;
        }

        if (startAge) {
            data += `&startAge=${startAge}`;
        }

        if (endAge) {
            data += `&endAge=${endAge}`;
        }

        if (gender) {
            data += `&gender=${gender}`;
        }

        if (searchSend !== "") {
            data += `&searchKey=${searchSend}`;
        }

        getDiseaseReport(data).then((res) => {
            localStorage.removeItem("diseaseReportData")
            if (res?.status === 200) {
                setDiseaseReport(res?.result?.diseaseReport || []);
                setTotalData(res?.result?.getTotalRecords || 0);
                setTotalPages(res?.result?.getTotalPages || 0);
                let updatedAttributes = { ...callbackAttributes };

                if (callbackAttributes.diseaseId) {
                    const foundDisease = disease.find(d => d.id === callbackAttributes.diseaseId);

                    if (foundDisease) {
                        updatedAttributes.diseaseId = foundDisease.diseaseName;
                    }
                }

                let diseaseReportData = {
                    callbackAttributes: updatedAttributes,
                    reportData: res?.result?.diseaseReport
                }
                localStorage.setItem("diseaseReportData", JSON.stringify(diseaseReportData))
            }
        });
    }, [callbackAttributes]);

    useEffect(() => {
        displayDiseaseList();
    }, [displayDiseaseList, callbackAttributes]);

    useEffect(() => {
        // getOrganization(1).then((res) => {
        //     if (res.status === 200) {
        //         const details = res?.result?.organizationDetails
        //         localStorage.setItem('organizationDetails', JSON.stringify(details))
        //     }
        // })
        getDisease().then((res) => {
            if (res?.status === 200) {
                setDisease(res?.result?.diseaseData || []);
                setTotalData(res?.result?.getTotalRecords || 0);
                setTotalPages(res?.result?.getTotalPages || 0);
            }
        });
    }, [])

    const handleChange = (name, value) => {
        // console.log("name", name)
        // console.log("value", value)
        if (name === "startAge" || name === "endAge") {
            if (value > 100) {
                setErrorMessage(`${name === "startAge" ? 'Start age' : 'End age'} can not be a greater then 100`)
                setCallbackAttributes(prev => {
                    return {
                        ...prev,
                        [name]: 100
                    }
                });
            } else {
                setErrorMessage(null)
                setCallbackAttributes(prev => {
                    return {
                        ...prev,
                        [name]: value
                    }
                });
            }
        } else {
            setCallbackAttributes(prev => {
                return {
                    ...prev,
                    [name]: value
                }
            });
        }
    };

    const clearFilters = () => {
        setCallbackAttributes((prev) => {
            return {
                ...prev,
                startDate: null,
                endDate: null,
                gender: null,
                startAge: null,
                endAge: null,
                diseaseId: null,
            }
        });
        setShowFilter(prev => !prev);
    }

    const filterComponent = () => {
        return (
            <div className="mb-4">
                <hr />
                <div className="flex justify-center my-2">
                    <h1 className="text-[1.5rem]">Filters</h1>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-3">
                    <div className="md:col-span-2">
                        <div className="flex-col">
                            <p className="text-sm leading-[21px] text-[#404040] mb-2">Start Apt. Date</p>
                            <div className="flex justify-between items-center text-[#404040] bg-white border border-[#CED4DA] rounded-[5px] w-full">
                                <ReactDatePicker
                                    selected={callbackAttributes.startDate ? new Date(callbackAttributes.startDate) : new Date()}
                                    className="outline-none rounded-[5px] py-[6px] px-[14px] bg-transparent h-[2.3rem]"
                                    closeOnScroll={true}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                        setCallbackAttributes(prev => {
                                            return {
                                                ...prev,
                                                startDate: dayjs(date).format("YYYY-MM-DD")
                                            }
                                        });
                                    }}
                                />
                                {/* <div className="flex justify-center items-center p-[9px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA]" >
                                    <i className="fa-solid fa-calendar-days"></i>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-2">
                        <div className="flex-col">
                            <p className="text-sm leading-[21px] text-[#404040] mb-2">End Apt. Date</p>
                            <div className="flex justify-between items-center text-[#404040] bg-white border border-[#CED4DA] rounded-[5px] w-full">
                                <ReactDatePicker
                                    selected={callbackAttributes.endDate ? new Date(callbackAttributes.endDate) : new Date()}
                                    className="outline-none rounded-[5px] py-[6px] px-[14px] bg-transparent h-[2.3rem]"
                                    closeOnScroll={true}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                        setCallbackAttributes(prev => {
                                            return {
                                                ...prev,
                                                endDate: dayjs(date).format("YYYY-MM-DD")
                                            }
                                        });
                                    }}
                                />
                                {/* <div className="flex justify-center items-center p-[9px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA]" >
                                    <i className="fa-solid fa-calendar-days"></i>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-2">
                        <TextInput
                            label="Start Age"
                            name="startAge"
                            type="number"
                            placeholder="Enter Start Age"
                            value={callbackAttributes.startAge ? callbackAttributes.startAge : ''}
                            onChange={handleChange}
                        />
                        <span className="text-red-600" style={{ height: 0 }}>{errorMessage && errorMessage?.startsWith('Start') ? errorMessage : null}</span>
                    </div>

                    <div className="md:col-span-2">
                        <TextInput
                            label="End Age"
                            name="endAge"
                            type="number"
                            placeholder="Enter End Age"
                            value={callbackAttributes.endAge ? callbackAttributes.endAge : ''}
                            onChange={handleChange}
                        />
                        <span className="text-red-600">{errorMessage && errorMessage.startsWith('End') ? errorMessage : null}</span>
                    </div>

                    <div className="md:col-span-2">
                        <Dropdown
                            label="Disease"
                            placeholder="Select Disease"
                            options={disease.map((row) => { return { label: row.diseaseName, value: row.id } })}
                            value={callbackAttributes?.diseaseId || null}
                            onChange={(e) => {
                                setCallbackAttributes(prev => {
                                    return {
                                        ...prev,
                                        diseaseId: e.value
                                    }
                                });
                            }}
                        />
                    </div>

                    <div className="md:col-span-2">
                        <Dropdown
                            label="Gender"
                            placeholder="Select Gender"
                            options={[
                                {
                                    label: 'Male',
                                    value: 'Male',
                                },
                                {
                                    label: 'Female',
                                    value: 'Female',
                                }
                            ]}
                            value={callbackAttributes?.diseaseId || null}
                            onChange={(e) => {
                                setCallbackAttributes(prev => {
                                    return {
                                        ...prev,
                                        gender: e.value
                                    }
                                });
                            }}
                        />
                    </div>
                </div>
                {/* <div className="grid grid-cols-8 mb-2 gap-2">

                </div> */}
                <div className="mb-2 flex justify-center">
                    <Button text="Clear Filters" onClick={clearFilters} />
                </div>
                <hr />
            </div>
        );
    }

    return (
        <div className="h-full">
            <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px] flex items-center md:block gap-5">
                <button className="md:hidden" onClick={handleDrawer}>
                    <i class="fa-solid fa-list"></i>
                </button>
                <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
                    Disease Report
                </p>
            </div>
            <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <div className="p-3 sm:p-7 sm:pt-5">
                        <TableWithSearchAndPagination
                            showReportButton={true}
                            headings={headings}
                            dataToShow={dataToShow}
                            data={diseaseReport}
                            totalData={totalData}
                            totalPages={totalPages}
                            setCallbackAttributes={setCallbackAttributes}
                            filterComponent={filterComponent}
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                            clearFilters={clearFilters}
                        />
                    </div>
                </div>
            </div>
            {/* <Dialog
                open={openModal}
                TransitionProps={{
                    style: {
                        height: "auto",
                    },
                }}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                        {diseaseInfo.id === 0
                            ? "Add Disease"
                            : "Edit Disease"}
                    </p>
                </DialogTitle>
                <DialogContent dividers>
                    <TextInput
                        validations={[
                            { type: "required", message: "Please enter disease name" },
                        ]}
                        value={diseaseInfo.diseaseName}
                        ref={ref}
                        label="Disease Name"
                        onChange={(name, value) => {
                            setDiseaseInfo((prev) => {
                                return {
                                    ...prev,
                                    diseaseName: value,
                                };
                            });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <div className="gap-2 flex">
                        <Button text="Cancel" onClick={handleModalClose} />
                        <Button
                            text={diseaseInfo.id === 0 ? "Add" : "Edit"}
                            onClick={handleAddAndEdit}
                        />
                    </div>
                </DialogActions>
            </Dialog> */}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        doctorId: state.user.doctorId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleDrawer: () => dispatch(handleDrawer()),
    };
}
    ;
export default WithAuth(WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(Report)));
