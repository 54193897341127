import React, { createRef, useEffect, useRef, useState } from "react";
import TextInput from "../../../components/textInput";
import Button from "../../../components/button";
import Dropdown from "../../../components/dropdown";
import TextArea from "../../../components/textArea";

const validations = {
    address: [
        {
            type: "required",
            message: "Please enter address",
        }
    ],
    city: [
        {
            type: "required",
            message: "Please enter city name",
        },
    ],
    state: [
        {
            type: "required",
            message: "Please select state",
        },
    ],
    primaryContactNumber: [
        {
            type: "required",
            message: "Please enter contact number",
        },
    ]
};

const statesOptions = [
    {
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh"
    },
    {
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh"
    },
    {
        "label": "Assam",
        "value": "Assam"
    },
    {
        "label": "Bihar",
        "value": "Bihar"
    },
    {
        "label": "Chhattisgarh",
        "value": "Chhattisgarh"
    },
    {
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "label": "Goa",
        "value": "Goa"
    },
    {
        "label": "Gujarat",
        "value": "Gujarat"
    },
    {
        "label": "Haryana",
        "value": "Haryana"
    },
    {
        "label": "Himachal Pradesh",
        "value": "Himachal Pradesh"
    },
    {
        "label": "Jharkhand",
        "value": "Jharkhand"
    },
    {
        "label": "Karnataka",
        "value": "Karnataka"
    },
    {
        "label": "Kerala",
        "value": "Kerala"
    },
    {
        "label": "Madhya Pradesh",
        "value": "Madhya Pradesh"
    },
    {
        "label": "Maharashtra",
        "value": "Maharashtra"
    },
    {
        "label": "Manipur",
        "value": "Manipur"
    },
    {
        "label": "Meghalaya",
        "value": "Meghalaya"
    },
    {
        "label": "Mizoram",
        "value": "Mizoram"
    },
    {
        "label": "Nagaland",
        "value": "Nagaland"
    },
    {
        "label": "Odisha",
        "value": "Odisha"
    },
    {
        "label": "Punjab",
        "value": "Punjab"
    },
    {
        "label": "Rajasthan",
        "value": "Rajasthan"
    },
    {
        "label": "Sikkim",
        "value": "Sikkim"
    },
    {
        "label": "Tamil Nadu",
        "value": "Tamil Nadu"
    },
    {
        "label": "Telangana",
        "value": "Telangana"
    },
    {
        "label": "Tripura",
        "value": "Tripura"
    },
    {
        "label": "Uttar Pradesh",
        "value": "Uttar Pradesh"
    },
    {
        "label": "Uttarakhand",
        "value": "Uttarakhand"
    },
    {
        "label": "West Bengal",
        "value": "West Bengal"
    }
]

const ContactInformation = (props) => {
    const inputRefs = useRef([
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
    ]);
    const [info, setInfo] = useState({
        address: "",
        city: "",
        state: "",
        pincode: "",
        primaryContactNumber: "",
        secondaryContactNumber: "",
        email: "",
    })

    useEffect(() => {
        if (props.info) {
            const patientInfo = props.info
            setInfo({
                address: patientInfo.address,
                city: patientInfo.city,
                state: patientInfo.state,
                pincode: patientInfo.pincode,
                primaryContactNumber: patientInfo.primaryContactNumber,
                secondaryContactNumber: patientInfo.secondaryContactNumber,
                email: patientInfo.email,
            })
        }
    }, [props.info])

    const handleChange = (name, value) => {
        setInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleNextClick = () => {
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (!isValid) {
            return;
        }
        props.saveInfo(info)
        props.goToStep(3)
    }
    const handleBackClick = () => {
        props.goToStep(1)
    }
    return (
        <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
            <TextArea
                label="Address"
                rows={3}
                name="address"
                ref={inputRefs.current[0]}
                value={info.address}
                onChange={handleChange}
                validations={validations.address}
            />
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9 mt-4 lg:mt-6">
                <TextInput
                    label="City"
                    name="city"
                    placeholder="Enter City Name"
                    value={info.city}
                    ref={inputRefs.current[1]}
                    onChange={handleChange}
                    validations={validations.city}
                // value={info.}
                />
                <Dropdown
                    options={statesOptions}
                    label="State"
                    placeholder="Select State"
                    value={info.state}
                    ref={inputRefs.current[2]}
                    onChange={(value) => {
                        handleChange("state", value.value)
                    }}
                    validations={validations.state}
                />
                <TextInput
                    label="Pincode"
                    placeholder="------"
                    value={info.pincode}
                    name="pincode"
                    ref={inputRefs.current[3]}
                    onChange={handleChange}
                />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                <TextInput
                    label="Contact Number 1"
                    placeholder="+91 ----- -----"
                    value={info.primaryContactNumber}
                    name="primaryContactNumber"
                    ref={inputRefs.current[4]}
                    onChange={handleChange}
                    validations={validations.primaryContactNumber}
                />
                <TextInput
                    label="Contact Number 2"
                    placeholder="+91 ----- -----"
                    value={info.secondaryContactNumber}
                    name="secondaryContactNumber"
                    ref={inputRefs.current[5]}
                    onChange={handleChange}
                />
                <TextInput
                    label="Email"
                    placeholder="email@domain.com"
                    value={info.email}
                    name="email"
                    ref={inputRefs.current[6]}
                    onChange={handleChange}
                />
            </div>
            <div className="flex items-center justify-between">
                <Button text="Previous" textStyle="mx-5 sm:mx-10" onClick={handleBackClick} />
                <Button text="Save & Next" textStyle="mx-5 sm:mx-10" onClick={handleNextClick} />
            </div>
        </div>
    )
}

export default ContactInformation