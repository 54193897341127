import React, { useState } from 'react';
import BlinkIcon from './blinkIcon';

const Modal = ({ isVisible, onClose, children, speechLanguage, setSpeechLanguage, handleSelectLanguage, showCloseIcon = true, width = "max-w-md", speechTextAreaStart, setSpeechTextAreaStart, speechTextArea, setTextareaValue, SpeechRecognition, resetTranscript }) => {
  
  if (!isVisible) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-colors z-50">
      <div className={`relative bg-white rounded-lg shadow-lg w-full p-6 ${width}`}>
        <div>
          <button
            className="absolute top-2 right-4 rounded-lg text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className='mt-4'>
          {
            showCloseIcon ? null :
              speechTextAreaStart ? (
                <div className='flex items-center w-full'>
                  <div className='grow'>
                    <span className='text-red-600 text-sm'>If you change language then  old diet plan will be remove</span>
                  </div>

                  <div>
                    <button
                      className={`rounded-lg text-red-600 text-lg`}
                      duration={5000}
                      onClick={() => {
                        if (speechTextAreaStart) {
                          SpeechRecognition.stopListening();
                          setTextareaValue((prev) => {
                            if (prev) {
                              return `<p>${prev}${speechTextArea}</p>`
                            }
                            return `<p>${speechTextArea}</p>`
                          });
                          // setTextareaValue((prev) => `<p>${prev}${speechTextArea}</p>`);                            
                          resetTranscript();
                          onClose()
                        }
                        setSpeechTextAreaStart(!speechTextAreaStart)
                      }}
                    >
                      <i class="fa-solid fa-record-vinyl"></i>
                    </button>
                  </div>
                </div>
              ) :
                (
                  <div className='flex items-center w-full'>
                    <div className='grow'>
                      <span className='text-red-600 text-sm'>If you change language then  old diet plan will be remove</span>
                    </div>
                    <div>
                      <button
                        className={`rounded-lg text-gray-800 text-lg`}
                        onClick={() => {
                          if (speechTextAreaStart) {
                            SpeechRecognition.stopListening();
                            // setTextareaValue((prev) => `<p>${prev}${speechTextArea}</p>`);
                            if (speechTextArea) {
                              setTextareaValue((prev) => {
                                if (prev) {
                                  return `<p>${prev}${speechTextArea}</p>`
                                }
                                return `<p>${speechTextArea}</p>`
                              });
                            }
                          } else {
                            setTimeout(() => {
                              SpeechRecognition.startListening({
                                continuous: true,
                                language: speechLanguage || 'en-In',
                              });
                            }, 1000);
                          }
                          setSpeechTextAreaStart(!speechTextAreaStart)
                        }}
                      >
                        <i class="far fa-microphone"></i>
                      </button>
                    </div>
                  </div>
                )
          }
          {
            !showCloseIcon ? (
              <div>
                <label for="small" class="block mb-2 text-sm font-medium text-gray-900">Select Language</label>
                <select value={speechLanguage} onChange={(e) => handleSelectLanguage(e.target.value)} id="small" class="block w-40 p-2 mb-6 text-sm text-gray-900 outline-none border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500">
                  <option value="en-In">English</option>
                  <option value="gu-In">Gujarati</option>
                  <option value="hi-In">Hindi</option>
                </select>
              </div>
            ) : null
          }
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
