import React, { useCallback, useEffect, useState } from "react";
import WithHeaderSidebar from "../../../layout/withHeaderSidebar";
import WithAuth from "../../../layout/withAuth";
import TableWithSearchAndPaginationAndFilter from "../../../components/tableWithSearchAndPaginationAndFilter";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { getOpdReport } from "../../../services/reports/opdReport";
import ReactDatePicker from "react-datepicker";
import { getAllDoctors } from "../../../services/doctor";
import Dropdown from "../../../components/dropdown";
import Button from "../../../components/button";
import { handleDrawer } from "../../../actions/userActions";


const headings = [
    {
        label: "Patient Name",
        width: "20%",
        align: "Left"
    },
    {
        label: "Appt. Date",
        width: "10%",
        align: "Left"
    },
    {
        label: "Appt. Time",
        width: "10%",
        align: "Left"
    },
    {
        label: "Doctor Name",
        width: "12%",
        align: "Left"
    },
    {
        label: "Priority",
        width: "8%",
        align: "Left"
    },
    {
        label: "Next Visit Date",
        width: "10%",
        align: "Left"
    },
    {
        label: "OPD Status",
        width: "12%",
        align: "Left"
    },
    {
        label: "Fees",
        width: "6%",
        align: "left"
    },
    {
        label: "Total Bill",
        width: "6%",
        align: "left"
    },
];

const dataToShow = [
    {
        key: "patientName",
        align: "left"
    },
    {
        key: "opdDate",
        align: "left"
    },
    {
        key: "opdTime",
        align: "left"
    },
    {
        key: "doctorName",
        align: "left"
    },
    {
        key: "priority",
        align: "left"
    },
    {
        key: "nextVisitOn",
        align: "left"
    },
    {
        key: "opdStatus",
        align: "left"
    },
    {
        key: "fees",
        align: "right"
    },
    {
        key: "allFees",
        align: "right"
    }
];

const Report = ({ doctorId, handleDrawer }) => {
    const [callbackAttributes, setCallbackAttributes] = useState({
        searchSend: "",
        perPage: "25",
        selectedPage: "0",
        sort: "appointmentId,desc",
        startAptDate: null,
        endAptDate: null,
        opdStatus: null,
        priority: null,
        doctorId: doctorId === 0 ? null : doctorId,
    });
    const [doctorList, setDoctorList] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [reportList, setReportList] = useState([]);
    const [totalFees, setTotalFees] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const clearFilters = () => {
        setCallbackAttributes((prev) => {
            return {
                ...prev,
                startAptDate: null,
                endAptDate: null,
                opdStatus: null,
                priority: null,
                doctorId: doctorId === 0 ? null : doctorId,
            }
        });
        setShowFilter(prev => !prev);
    }
    const opdReportRecords = useCallback(() => {
        setReportList([]);
        const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
        let data = `page=${selectedPage}&size=${perPage}&sort=${sort}`;
        let payload = {
            searchKey: searchSend,
            startAptDate: callbackAttributes.startAptDate,
            endAptDate: callbackAttributes.endAptDate,
            opdStatus: callbackAttributes.opdStatus,
            priority: callbackAttributes.priority,
            doctorId: callbackAttributes.doctorId
        }
        getOpdReport(data, payload).then((res) => {
            if (res?.status === 200) {
                const appointmentList = res?.result?.opdReport?.map((item) => {
                    return {
                        ...item,
                        priority: (
                            <p
                                className={`${item.priority === "Very Urgent"
                                    ? "text-[#FF0000]"
                                    : item.priority === "Normal"
                                        ? "text-[#00ADEE]"
                                        : "text-[#80509F]"
                                    }`}
                            >
                                {item.priority}
                            </p>
                        ),
                        fees: parseFloat(item.fees).toFixed(2),
                        allFees: parseFloat(item.allFees).toFixed(2),
                        opdDate: dayjs(item.opdDate).format("DD/MM/YYYY"),
                        nextVisitOn: item.nextVisitOn !== "" ? dayjs(item.nextVisitOn).format("DD/MM/YYYY") : ""
                    }
                });
                setReportList(appointmentList || []);
                setTotalData(res?.result?.getTotalRecords || 0);
                setTotalPages(res?.result?.getTotalPages || 0);
                setTotalFees(res?.result?.totalFees || 0);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callbackAttributes]);
    useEffect(() => {
        opdReportRecords();
    }, [opdReportRecords, callbackAttributes])
    useEffect(() => {
        getAllDoctors().then((res) => {
            if (res.status === 200) {
                const doctorList = res?.result?.doctorList?.map((doctor) => {
                    return {
                        label: doctor.docName,
                        value: doctor.docId,
                    };
                });
                setDoctorList(doctorList || []);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const extraRow = () => {
        return (<tr>
            <td colSpan={7} className="text-right hover:none text-black">
            </td>
            <td className="hover:none text-black">
                <strong> Total : </strong>
            </td>
            <td className="hover:none text-black">
                {totalFees.toFixed(2)}
            </td>
        </tr>)
    }
    const filterComponent = () => {
        return (
            <div className="mb-4">
                <hr />
                <div className="flex justify-center">
                    <h1 className="text-[1.5rem]">Filters</h1>
                </div>
                <div className="grid grid-cols-8 mb-4 mt-4">
                    <div></div>
                    <div className="col-span-3 pr-8">
                        <div className="flex-col">
                            <p className="text-sm leading-[21px] text-[#404040] mb-2">Start Apt. Date</p>
                            <div className="flex justify-between items-center text-[#404040] bg-white border border-[#CED4DA] rounded-[5px] w-full">
                                <ReactDatePicker
                                    selected={callbackAttributes.startAptDate ? new Date(callbackAttributes.startAptDate) : new Date()}
                                    className="outline-none rounded-[5px] py-[6px] px-[14px] bg-transparent h-[2rem]"
                                    closeOnScroll={true}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                        setCallbackAttributes(prev => {
                                            return {
                                                ...prev,
                                                startAptDate: dayjs(date).format("YYYY-MM-DD HH:mm:ss")
                                            }
                                        });
                                    }}
                                />
                                <div className="flex justify-center items-center p-[9px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA]" >
                                    <i className="fa-solid fa-calendar-days"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 pl-8">
                        <div className="flex-col">
                            <p className="text-sm leading-[21px] text-[#404040] mb-2">End Apt. Date</p>
                            <div className="flex justify-between items-center text-[#404040] bg-white border border-[#CED4DA] rounded-[5px] w-full">
                                <ReactDatePicker
                                    selected={callbackAttributes.endAptDate ? new Date(callbackAttributes.endAptDate) : new Date()}
                                    className="outline-none rounded-[5px] py-[6px] px-[14px] bg-transparent h-[2rem]"
                                    closeOnScroll={true}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                        setCallbackAttributes(prev => {
                                            return {
                                                ...prev,
                                                endAptDate: dayjs(date).format("YYYY-MM-DD HH:mm:ss")
                                            }
                                        });
                                    }}
                                />
                                <div className="flex justify-center items-center p-[9px] bg-[#E9ECEF] rounded-r-[5px] border-l border-[#CED4DA]" >
                                    <i className="fa-solid fa-calendar-days"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
                <div className="grid grid-cols-8 mb-2 gap-2">
                    <div>

                    </div>
                    <div className="col-span-2">
                        <Dropdown
                            label="Doctor"
                            placeholder="Select Doctor"
                            options={doctorList}
                            value={callbackAttributes.doctorId}
                            onChange={(val) => {
                                setCallbackAttributes((prev) => {
                                    return {
                                        ...prev,
                                        doctorId: val.value
                                    }
                                });
                            }}
                        />
                    </div>
                    <div className="col-span-2">
                        <Dropdown
                            label="Priority"
                            placeholder="Select Priority"
                            options={[
                                { label: "All", value: "All" },
                                { label: "Normal", value: "Normal" },
                                { label: "Urgent", value: "Urgent" },
                                { label: "Very Urgent", value: "Very Urgent" },
                            ]}
                            value={callbackAttributes.priority}
                            onChange={(val) => {
                                setCallbackAttributes((prev) => {
                                    return {
                                        ...prev,
                                        priority: val.value
                                    }
                                });
                            }}
                            indicatorContainerStyle="border-l-0 bg-white ml-1"
                        />
                    </div>
                    <div className="col-span-2">
                        <Dropdown
                            label="OPD Status"
                            placeholder="Select OPD Status"
                            options={[
                                { label: "Complete", value: true },
                                { label: "Incomplete", value: false },
                            ]}
                            value={callbackAttributes.opdStatus}
                            onChange={(val) => {
                                setCallbackAttributes((prev) => {
                                    return {
                                        ...prev,
                                        opdStatus: val.value
                                    }
                                });
                            }}
                            indicatorContainerStyle="border-l-0 bg-white ml-1"
                        />
                    </div>
                </div>
                <div className="mb-2 flex justify-center">
                    <Button text="Clear Filters" onClick={clearFilters} />
                </div>
                <hr />
            </div>
        );
    }
    return (
        <div className="h-full">
            <div className="pl-8 py-7 flex items-center md:block gap-5">
                <button className="md:hidden" onClick={handleDrawer}>
                    <i class="fa-solid fa-list"></i>
                </button>
                <p className="text-2xl leading-6 text-[#404040] font-bold">
                    OPD Report
                </p>
            </div>
            <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <div className="p-3 sm:p-7 sm:pt-5">
                        <TableWithSearchAndPaginationAndFilter
                            headings={headings}
                            dataToShow={dataToShow}
                            data={reportList}
                            totalData={totalData}
                            totalPages={totalPages}
                            actions={[]}
                            setCallbackAttributes={setCallbackAttributes}
                            module="OPD Report"
                            extraRow={extraRow}
                            filterComponent={filterComponent}
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                            clearFilters={clearFilters}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        doctorId: state.user.doctorId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleDrawer: () => dispatch(handleDrawer()),
    };
};
export default WithAuth(
    WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(Report))
);
