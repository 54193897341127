import { RESET_USER, SET_USER, DRAWER } from "../config/actionTypes";

const initialState = {
  userDetails: {},
  permission: {},
  doctorId: 0,
  openMobileDrawer: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    case RESET_USER:
      return initialState;
    case DRAWER:
      return { ...state, openMobileDrawer: !state.openMobileDrawer };
    default:
      return state;
  }
};
