import React, { useState } from "react";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import { useParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import EditUserInfo from "./editUserInfo";
import EditUserPolicy from "./editUserPolicy";
import { connect } from "react-redux";
import ResetPassword from "./resetPassword";
import { handleDrawer, resetUser } from "../../actions/userActions";

const checkDefaultSelected = (userPermissions) => {
  if (userPermissions?.["User's General Info Edit"]?.includes("Show Tab")) {
    return "info";
  } else if (userPermissions?.["User's Policy Edit"]?.includes("Show Tab")) {
    return "policy";
  } else if (userPermissions?.["User's Password Reset"]?.includes("Show Tab")) {
    return "resetPass";
  }
  return "";
};

const EditUser = ({ userDetails, handleDrawer, resetUser = () => { }, userPermissions }) => {
  const { id } = useParams();
  const defaultSelected = checkDefaultSelected(userPermissions);
  const [value, setValue] = useState(defaultSelected);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px] flex items-center md:block gap-5">
        <button className="md:hidden" onClick={handleDrawer}>
          <i class="fa-solid fa-list"></i>
        </button>
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Users
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Edit User
            </p>
          </div>
          <div className="border-b mx-2 sm:mx-5 pt-2 sm:pt-4">
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              {userPermissions?.["User's General Info Edit"]?.includes(
                "Show Tab"
              ) ? (
                <Tab
                  value="info"
                  label="User Info"
                  style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                />
              ) : null}
              {userPermissions?.["User's Policy Edit"]?.includes("Show Tab") ? (
                <Tab
                  value="policy"
                  label="User Policy"
                  style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                />
              ) : null}
              {userDetails?.userId?.toString() === id &&
                userPermissions?.["User's Password Reset"]?.includes(
                  "Show Tab"
                ) ? (
                <Tab
                  value="resetPass"
                  label="Reset Password"
                  style={{ fontSize: 14, lineHeight: "20px", fontWeight: 500 }}
                />
              ) : null}
            </Tabs>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 p-3 sm:p-7 sm:pt-5">
            {value === "info" ? (
              <EditUserInfo
                userId={id}
                userDetails={userDetails}
                resetUser={resetUser}
              />
            ) : value === "policy" ? (
              <EditUserPolicy
                userId={id}
                userDetails={userDetails}
                resetUser={resetUser}
              />
            ) : (
              <ResetPassword userDetails={userDetails} resetUser={resetUser} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.user.userDetails,
    userPermissions: state.user.permission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetUser: () => dispatch(resetUser()),
    handleDrawer: () => dispatch(handleDrawer()),
  };
};

export default WithAuth(
  WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(EditUser))
);
