import React, { createRef, useEffect, useRef, useState } from "react";
import ImageUpload from "../../components/imageUpload";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import OrganizationInfo from "./organizationInfo";
import { addOrganization, getOrganization, updateOrganization } from "../../services/organization";
import { connect } from "react-redux";
import { handleDrawer } from "../../actions/userActions";
import Modal from "../../components/modal";
import SuccessBtn from "../../components/successBtn";

const AddOrganization = ({ userDetails, handleDrawer }) => {
    const navigate = useNavigate();
    const [isUpdate, setIsUpdate] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);

    const [organizationInfo, setOrganizationInfo] = useState({
        orgName: "",
        orgEmail: "",
        orgAddress: "",
        orgLogo: ""
    });

    const inputRefs = useRef([
        createRef(),
        createRef(),
        createRef(),
        createRef(),
    ]);
    const openModal = () => {
        setModalVisible(true)
    };
    const closeModal = () => setModalVisible(false);
    const handleChange = (name, value) => {
        setOrganizationInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleRegister = () => {
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length - 1; i++) {
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (!isValid) {
            return;
        }
        if (isUpdate) {
            updateOrganization(1, organizationInfo).then((res) => {
                if (res.status === 200) {
                    // navigate("/dashboard");
                    localStorage.setItem('organizationDetails', JSON.stringify(organizationInfo))
                    openModal()
                }
            });
            return
        }
        addOrganization(organizationInfo).then((res) => {
            if (res.status === 200) {
                openModal()
                localStorage.setItem('organizationDetails', JSON.stringify(organizationInfo))
                // navigate("/dashboard");
            }
        });
    };

    const handleGetOrganizationInfo = () => {
        getOrganization(1).then((res) => {
            if (res.status === 200) {
                setOrganizationInfo(res.result.organizationDetails)
                setIsUpdate(true)
            }
        })
    }

    useEffect(() => {
        handleGetOrganizationInfo()
    }, [])
    return (
        <div className="h-full">
            <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px] flex items-center md:block gap-5">
                <button className="md:hidden" onClick={handleDrawer}>
                    <i class="fa-solid fa-list"></i>
                </button>
                <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
                    Organization Detail
                </p>
            </div>
            <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
                        <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                            {
                                isUpdate ? "Update Organization Detail" : "Add Organization Detail"
                            }
                        </p>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 sm:gap-x-5 p-3 sm:p-7 sm:pt-5">
                        <div className="w-full">
                            <OrganizationInfo
                                organizationInfo={organizationInfo}
                                handleChange={handleChange}
                                inputRefs={inputRefs}
                            />
                        </div>
                        <div className="w-full">
                            <ImageUpload
                                label="Organization's Photo"
                                image={organizationInfo.orgLogo}
                                disabled={false}
                                fileType="organizationProfile"
                                userType="user"
                                userId={userDetails?.userId}
                                onImageUpload={(imagePath, imageName) => {
                                    handleChange("orgLogo", imagePath)
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-5 p-5">
                        <Button
                            text="Cancel"
                            onClick={() => {
                                navigate("/dashboard");
                            }}
                        />
                        <Button text="Submit" onClick={handleRegister} />
                    </div>
                </div>
            </div>
            <Modal
                isVisible={isModalVisible}
                onClose={closeModal}
            >
                <div className="flex justify-center mb-4">
                    <i className="far fa-check-circle fa-2x text-[#118C42] "></i>
                </div>
                <p className="text-center mb-4">Organization {isUpdate ? 'Update' : 'Create'} Successfully.</p>
                <div className="flex justify-center">
                    <SuccessBtn
                        text="DONE"
                        onClick={() => {
                            closeModal();
                            setIsUpdate(false)
                            // navigate("/opd-appointment/manage");
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        userDetails: state.user.userDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleDrawer: () => dispatch(handleDrawer()),
    };
};
export default WithAuth(WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(AddOrganization)));
