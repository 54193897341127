import React, { useCallback, useEffect, useState } from "react";
import TableWithSearchAndPagination from "../../../components/tableWithSearchAndPagination";
import Button from "../../../components/button";
import WithHeaderSidebar from "../../../layout/withHeaderSidebar";
import WithAuth from "../../../layout/withAuth";
import {
  allModuleListPage,
  deleteModuleById,
} from "../../../services/permissions/modules";
import { useNavigate } from "react-router-dom";
import CheckPermission from "../../../components/checkPermission";
import { connect } from "react-redux";
import { handleDrawer } from "../../../actions/userActions";

const headings = [
  {
    label: "Module Id",
    width: "20%",
    align: "left",
  },
  {
    label: "Module Name",
    width: "40%",
    align: "left",
  },
  {
    label: "Functionality Name",
    align: "left",
    width: "30%",
  },
  {
    label: "Actions",
    width: "10%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "moduleId",
    align: "left",
  },
  {
    key: "moduleName",
    align: "left",
  },
  {
    key: "functionalityName",
    align: "left",
  },
];

const Modules = ({ handleDrawer }) => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "",
  });
  const [modulesList, setModuleList] = useState([]);

  const displayModuleList = useCallback(() => {
    setModuleList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    allModuleListPage(data).then((res) => {
      if (res?.status === 200) {
        setModuleList(res?.result?.modulesList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayModuleList();
  }, [displayModuleList, callbackAttributes]);

  const handleDelete = (item) => {
    deleteModuleById(item.moduleId).then((res) => {
      if (res.status === 200) {
        displayModuleList();
      }
    });
  };

  const handleEdit = (item) => {
    navigate("/modules/edit/" + item.moduleId);
  };

  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px] flex items-center md:block gap-5">
        <button className="md:hidden" onClick={handleDrawer}>
          <i class="fa-solid fa-list"></i>
        </button>
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Modules
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Modules List
            </p>
            <CheckPermission module="Modules" permission="Add">
              <Button
                text="New Entry"
                onClick={() => {
                  navigate("/modules/add");
                }}
              />
            </CheckPermission>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={modulesList}
              totalData={totalData}
              totalPages={totalPages}
              actions={["delete", "edit"]}
              setCallbackAttributes={setCallbackAttributes}
              handleActionEdit={handleEdit}
              handleActionDelete={handleDelete}
              module="Modules"
              actionPermission={{
                edit: "Edit",
                delete: "Delete",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorId: state.user.doctorId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawer: () => dispatch(handleDrawer()),
  };
};

export default WithAuth(WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(Modules)));
