import React, { useEffect, useCallback } from "react";
import pdfMake from "pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import dayjs from "dayjs";

const logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAABHNCSVQICAgIfAhkiAAABF1JREFUaEPtmV9WGjEUxm84vhd0AeIOcAXiCkoP9lnoBqwrcFxBcQOCz4XD7EBYgbgDXICOvtdJv5tkhnTKwISM2J5jzhkFJn9++XKTe5MIKjEFrX4T1Z3haeCpm6ongiiURDdB2H0uqznU6Z8AXEUtYzwMnpcYugv40L9FIm9wA32rVZYPqDLA5zBRF+/5d/wmPhtghh/4wpcAfg0FGUre438zzxzQgQ5g+3hY+UPkm/vAe4FrNelOknwRJOrrbBj5e8iPOSBh79+4IxsnX3ADQueA5s8rkzGriDMhv1fbXoWD1vUE5nFkhn62DlwBL8ocAx7lN0ulgLuotzAX+u/AA2h8gecD3NnYEnt1NJV/QfF+Hb2tArzQxNSTs//+4M5D9AFeQqziqvrbmco4alAcn5IU7M5LSOKKTmppRJgLPnoM0CY7MzshrhEhyt9kQRYOaBxV6VX2ES+2SqC1qhDn1K6l4UA++BN70Sx4Us+cYtGlr7XU02pwDX0L6AZJeiEhexRXJnbGsjpT2FTGUZ1iju9lZ9EhwLdrA2bR4KO0t/dUEU36Uittp5LtcGFwu+Ao+o4O/FA/VcQh+GaCfkZNqkBtogf82HhLaG53I3AlLuYAiQtYxIROdo8FDZ9C6I6NwGIYXMyCQ9V1cbhd38bg2jLm+LsPgQ+E9aXmqjZcPu9+jgBeK9pZL/DhY4+EOMNEheKjJ2zAkdq7ziHu1mOVxFxIXrKpPMNUPr0pOPsGTChj4+wfeKUIXExMLyLGzkmcs+IT/HSUzNaiQ64h9A5oZXSYTH4p4Yj2sDp4pIRVm4pZaqS8QcWsROFUCHz4OIBdnqpKMamgPE8w95QKAD9zslsVxvnMlbk4rixrwdmxxVJtjlXaQBxVznaQsG9q7wV6Qg6jFrwln0Qh4UCnQldFVpi14Onai9EkgTYgjqvqrLSA82GvTjTFXGwqylSNUdSBIlhuWHlWBwu9kJhQlYUXzXRoKbgK0tgvqEo6+FNXsHGMz+xAjEmq0VDnjMuTlA0DWzcZpqinlQj65xLI8cFrHKQ2ma0Sk8KOX5aCpw4tLaxV0sOtTVJ1RA6QIy+oslvWx3omRkle5K/dPETZZEVn/CpH8Tr9Sk9qiXZolppdupzRXI0E0T2cSf5Ks4N8OZPZ2enYfVlr49mO26rzOxMw5ZrLihfbBWcQy/vx6rAJNJfZPji3ymaYMTvXDrwPuCvlkvwf4EVFRFjLqwifHXod7m9dca943FKnFHDUd1D0asRsPtizvutprbn/UbdpgyLmAnA4H7Hv0tll9Xoqnl5ITQB+vA7c3INiYy4fcAfEnnPj5AXOrVoKrrwHMvc/dyjCwIVHKK9nJYCr22Q+3mBvFlyE3ctsY+Z2jq8K+c5zCrW5jFfyBteqpybDX2HDFKJiFQ5jJ86QBlROOS533msu6WIp4Aae1cQZ4V8Hl4BX96A9AAdeMluFSwNP6oT6bMO8W+GHVZ/5XAvmdfQ3oW5I5Uzz8CkAAAAASUVORK5CYII="

// const documentDefinition = {
//     pageSize: {
//         width: 595,
//         height: 250
//     },
//     content: [
//         {
//             image: logo,
//             width: 25,
//             margin: [0, 0, 0, 10],
//             style: { alignment: "center" }
//         },
//         {
//             table: {
//                 widths: ['100%'],
//                 body: [
//                     [
//                         { border: [true, true, true, true], text: 'PULSE EMR', alignment: "center", fontSize: 10 }
//                     ]
//                 ]
//             },
//             margin: [0, 0, 0, 10]
//         }
//     ],
//     styles: {
//         tableStyle: {
//             fontSize: 9,
//             alignment: "center"
//         },
//         tableHeader: {
//             bold: true,
//             fontSize: 9,
//             color: 'black',
//             alignment: "center",
//             fillColor: "#e5e5e5"
//         },
//         stackStyle: {
//             border: "1px solid #cccccc"
//         }
//     }
// };

let organization = JSON.parse(localStorage.getItem('organizationDetails'))
const documentDefinition = {
    pageSize: {
        width: 595,
        height: 320
    },
    content: [
        {
            columns: [
                {
                    width: '20%',
                    image: 'logo',
                    width: 35,
                    margin: [0, 0, 0, 0],
                    alignment: "center"
                },
                {
                    width: '80%',
                    stack: [
                        {
                            text: organization?.orgName,
                            style: 'subHeaderTitle',
                        },
                        {
                            text: organization?.orgEmail,
                            style: 'normalText',
                        },
                        {
                            text: organization?.orgAddress,
                            style: 'normalText',
                        },
                    ],
                    margin: [0, 0, 10, 0]
                }
            ],
            columnGap: 20
        },
        {
            table: {
                widths: ['100%'],
                body: [
                    [
                        {
                            border: [false, true, false, false],
                            text: '',
                            alignment: "center",
                            fontSize: 10,
                        }
                    ]
                ]
            },
            margin: [0, 10, 0, 10],
            layout: {
                hLineColor: function (i, node) {
                    return (i === 0) ? 'black' : 'red'; // Color for the top border
                },
                // vLineColor: function (i, node) {
                //     return 'transparent'; // No vertical lines
                // }
            }
        },
    ],
    footer: function (currentPage, pageCount) {
        return {
            columns: [

                {
                    width: '20%',
                    alignment: 'left',
                    stack: [
                        {
                            image: logo,
                            width: 25,
                            margin: [40, 0, 0, 2],
                        },
                    ],
                    margin: [0, 0, 10, 0]
                },
                {
                    width: '*',
                    alignment: 'right',
                    stack: [
                        {
                            text: 'PULSE EMR',
                            style: 'normalText',
                            margin: [40, 10, 0, 2],
                        },
                    ],
                    margin: [0, 0, 10, 0]
                },
            ],
            margin: [0, 0, 10, 20]
        };
    },
    styles: {
        headerTitle: {
            fontSize: 16,
            bold: true
        },
        subHeaderTitle: {
            fontSize: 12,
            bold: true
        },
        normalText: {
            fontSize: 10,
            lineHeight: 1.2
        },
        thankYouText: {
            fontSize: 12,
            bold: true,
            color: 'black'
        }
    },
    images: {
        logo: organization?.orgLogo,
    },
};

const OpdReportPdf = () => {
    const createPdf = useCallback(() => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).download(`appointment.pdf`);
        setTimeout(function () {
            window.close();
        }, 1000);
    }, []);
    const executePrint = useCallback((pdfData) => {
        let mainBody = {
            columns: [
                {
                    width: "40%",
                    table: {
                        widths: ["30%", "70%"]
                    }
                },
                {
                    width: "60%",
                    table: {
                        widths: ["40%", "60%"]
                    }
                }
            ],
            columnGap: 10
        }
        let table1Content = [];
        let table2Content = [];
        table1Content.push([{ text: "Patient Information", style: "tableHeader", alignment: 'center', fillColor: '#F7F7F7', colSpan: 2 }, {}]);
        table2Content.push([{ text: "Appointment Information", style: "tableHeader", alignment: 'center', fillColor: '#F7F7F7', colSpan: 2 }, {}]);
        table1Content.push([
            {
                text: "Name",
                style: "tableHeader"
            },
            {
                text: pdfData.patientName,
                style: "tableStyle"
            }
        ]);
        table1Content.push([
            {
                text: "Gender",
                style: "tableHeader"
            },
            {
                text: pdfData.patientGender,
                style: "tableStyle"
            }
        ]);
        table1Content.push([
            {
                text: "Age",
                style: "tableHeader"
            },
            {
                text: pdfData.patientAge,
                style: "tableStyle"
            }
        ]);
        table1Content.push([
            {
                text: "Occupation",
                style: "tableHeader"
            },
            {
                text: pdfData.patientOccupation,
                style: "tableStyle"
            }
        ]);
        table2Content.push([
            {
                text: "Appointment ID",
                style: "tableHeader"
            },
            {
                text: pdfData.appointmentId,
                style: "tableStyle"
            }
        ]);
        table2Content.push([
            {
                text: "Appointment Date",
                style: "tableHeader"
            },
            {
                text: dayjs(new Date(pdfData.appointmentDate)).format("DD-MM-YYYY"),
                // text:'ok',
                style: "tableStyle"
            }
        ]);
        table2Content.push([
            {
                text: "Appointment Time",
                style: "tableHeader"
            },
            {
                text: `${pdfData.appointmentTime.split(":")[0]}:${pdfData.appointmentTime.split(":")[1]} ${pdfData.appointmentTime.split(" ")[1]}`,
                // text:'ok',
                style: "tableStyle"
            }
        ]);
        table2Content.push([
            {
                text: "Doctor Name",
                style: "tableHeader"
            },
            {
                text: pdfData.doctorName,
                style: "tableStyle"
            }
        ]);
        table2Content.push([
            {
                text: "Priority",
                style: "tableHeader"
            },
            {
                text: pdfData.priority,
                style: "tableStyle"
            }
        ]);
        mainBody.columns[0].table.body = table1Content
        mainBody.columns[1].table.body = table2Content
        documentDefinition.content.push(mainBody);
        createPdf();
    }, [])
    useEffect(() => {
        let pdfData = JSON.parse(sessionStorage.getItem("pdfData"));
        executePrint(pdfData);
        sessionStorage.removeItem("pdfData");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executePrint])
    return (
        <div></div>
    );
}

export default OpdReportPdf;
