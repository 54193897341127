import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { digiesUrl } from "../config/apiConfig";


export const getDisease = (data) => {
    if (data) {
        return axiosInterceptors()
            .get(digiesUrl + `/getalldisease?${data}`)
            .then((res) => res);
    }
    return axiosInterceptors()
        .get(digiesUrl + `/getalldisease`)
        .then((res) => res);
}

export const getDiseaseById = (id) => {
    return axiosInterceptors()
        .get(digiesUrl + `/getdisease/${id}`)
        .then((res) => res);
}

export const addDisease = (data) => {
    return axiosInterceptors()
        .post(digiesUrl + "/adddisease", data)
        .then((res) => res);
}

export const updateDisease = (id, data) => {
    return axiosInterceptors()
        .patch(digiesUrl + `/editdisease/${id}`, data)
        .then((res) => res);
}

export const deleteDisease = (id) => {
    return axiosInterceptors()
        .delete(digiesUrl + `/deletedisease/${id}`)
        .then((res) => res);
}

// export const updateOrganization = (id,data) => {
//     return axiosInterceptors()
//     .patch(digiesUrl + `/addOrganization/${id}`, data)
//     .then((res) => res);
// }
