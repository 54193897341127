import React, { useCallback, useEffect, useState } from "react";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import TableWithSearchAndPagination from "../../components/tableWithSearchAndPagination";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import { deleteUser, getUserListPage } from "../../services/user";
import { connect } from "react-redux";
import CheckPermission from "../../components/checkPermission";
import { handleDrawer } from "../../actions/userActions";

const headings = [
  {
    label: "User Id",
    width: "10%",
    align: "left",
  },
  {
    label: "First Name",
    width: "12%",
    align: "left",
  },
  {
    label: "Last Name",
    width: "13%",
    align: "left",
  },
  {
    label: "User Name",
    width: "20%",
    align: "left",
  },
  {
    label: "Mobile Number",
    width: "15%",
    align: "left",
  },
  {
    label: "Role",
    width: "15%",
    align: "left",
  },
  {
    label: "Actions",
    width: "15%",
    align: "center",
  },
];

const dataToShow = [
  {
    key: "userId",
    align: "left",
  },
  {
    key: "firstName",
    align: "left",
  },
  {
    key: "lastName",
    align: "left",
  },
  {
    key: "userName",
    align: "left",
  },
  {
    key: "mobileNumber",
    align: "left",
  },
  {
    key: "roleName",
    align: "left",
  },
];

const ManageUsers = ({ userDetails, handleDrawer }) => {
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [callbackAttributes, setCallbackAttributes] = useState({
    searchSend: "",
    perPage: "25",
    selectedPage: "0",
    sort: "userId,asc",
  });
  const [usersList, setUsersList] = useState([]);

  const displayUsersList = useCallback(() => {
    setUsersList([]);
    const { searchSend, selectedPage, perPage, sort } = callbackAttributes;
    let data = `searchKey=${searchSend}&page=${selectedPage}&size=${perPage}&sort=${sort}`;
    getUserListPage(data).then((res) => {
      if (res.status === 200) {
        const usersList = res.result?.usersList.map((userItem) => {
          return {
            ...userItem,
            actions:
              userItem.userId === userDetails.userId
                ? ["edit"]
                : userItem.roleName === "Owner"
                  ? []
                  : ["delete", "edit"],
          };
        });
        setUsersList(usersList || []);
        setTotalData(res?.result?.getTotalRecords || 0);
        setTotalPages(res?.result?.getTotalPages || 0);
      } else {
        // props?.setSnackbar({
        //     showSnackbar: true,
        //     message: res?.message,
        //     variant: "error"
        // })
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackAttributes]);

  useEffect(() => {
    displayUsersList();
  }, [displayUsersList, callbackAttributes]);

  const handleDelete = (item) => {
    deleteUser(item.userId).then((res) => {
      if (res.status === 200) {
        displayUsersList();
      }
    });
  };

  const handleEdit = (item) => {
    navigate("/users/edit/" + item.userId);
  };

  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px] flex items-center md:block gap-5">
        <button className="md:hidden" onClick={handleDrawer}>
          <i class="fa-solid fa-list"></i>
        </button>
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Users
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              Users List
            </p>
            <CheckPermission module="Users" permission="Add">
              <Button
                text="New Entry"
                onClick={() => {
                  navigate("/users/add");
                }}
              />
            </CheckPermission>
          </div>
          <div className="p-3 sm:p-7 sm:pt-5">
            <TableWithSearchAndPagination
              headings={headings}
              dataToShow={dataToShow}
              data={usersList}
              totalData={totalData}
              totalPages={totalPages}
              setCallbackAttributes={setCallbackAttributes}
              handleActionEdit={handleEdit}
              handleActionDelete={handleDelete}
              module="Users"
              actionPermission={{
                edit: "Edit",
                delete: "Delete",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.user.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawer: () => dispatch(handleDrawer()),
  };
};
export default WithAuth(
  WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(ManageUsers))
);
