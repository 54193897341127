import React, { createRef, useEffect, useRef, useState } from "react";
import TextInput from "../../../components/textInput";
import DatePicker from "../../../components/datePicker";
import Button from "../../../components/button";
import Dropdown from "../../../components/dropdown";
import ImageUpload from "../../../components/imageUpload";
import { getAllDoctors } from "../../../services/doctor";
import dayjs from "dayjs";
import Label from "../../../components/label";
import TimePicker from "../../../components/timePicker";

const validations = {
    date: [
        {
            type: "required",
            message: "Please select date",
        }
    ],
    time: [
        {
            type: "required",
            message: "Please enter time",
        },
    ],
    firstName: [
        {
            type: "required",
            message: "Please enter first name",
        },
    ],
    lastName: [
        {
            type: "required",
            message: "Please enter last name",
        },
    ],
    gender: [
        {
            type: "required",
            message: "Please select gender",
        },
    ],
    age: [
        {
            type: "required",
            message: "Please enter age",
        },
    ],
    priority: [
        {
            type: "required",
            message: "Please select priority.",
        },
    ],
    maritalStatus: [
        {
            type: "required",
            message: "Please select marital status.",
        },
    ],
    doctorId: [
        {
            type: "required",
            message: "Please select doctor",
        },
    ],
};
const maritalStatusOptions = [
    {
        label: "Married",
        value: "Married"
    },
    {
        label: "Unmarried",
        value: "Unmarried"
    },
    {
        label: "Divorced",
        value: "Divorced"
    },
    {
        label: "Widow",
        value: "Widow"
    }
]

const genderOptions = [
    {
        label: "Male",
        value: "Male"
    },
    {
        label: "Female",
        value: "Female"
    }
]

const priorityOptions = [
    {
        label: "Normal",
        value: "Normal"
    },
    {
        label: "Urgent",
        value: "Urgent"
    },
    {
        label: "Very Urgent",
        value: "Very Urgent"
    }
]

const PatientInformation = (props) => {
    const [doctorList, setDoctorList] = useState([])
    const inputRefs = useRef([
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef()
    ]);
    const [info, setInfo] = useState({
        patientId: "0",
        date: new Date(),
        time: dayjs(new Date()).format('hh:mm:ss a').toUpperCase(),
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        age: "",
        priority: "",
        maritalStatus: "",
        doctorId: "",
        profilePicture: "",
        occupation: ""
    })

    useEffect(() => {
        if (props.info) {
            const patientInfo = props.info
            setInfo({
                patientId: patientInfo.patientId,
                date: new Date(patientInfo.date),
                time: patientInfo.time,
                firstName: patientInfo.firstName,
                middleName: patientInfo.middleName,
                lastName: patientInfo.lastName,
                gender: patientInfo.gender,
                age: patientInfo.age.toString(),
                priority: patientInfo.priority,
                maritalStatus: patientInfo.maritalStatus,
                doctorId: patientInfo.doctorId,
                profilePicture: patientInfo.profilePicture,
                occupation: patientInfo.occupation
            })
        }
    }, [props.info])
    const handleChange = (name, value) => {
        setInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleNextClick = () => {
        let isValid = true;
        const skipValidation = [0, 1, 7, 9]
        for (let i = 0; i < inputRefs.current.length; i++) {
            if (info.patientId !== "0" && skipValidation.includes(i)) {
                continue
            }
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (!isValid) {
            return;
        }
        props.saveInfo({
            ...info,
            date: dayjs(info.date).format('YYYY-MM-DD HH:mm:ss')
        })
        props.goToStep(2)
    }

    useEffect(() => {
        getAllDoctors().then(res => {
            if (res.status === 200) {
                const doctorList1 = res?.result?.doctorList?.map(doctor => {
                    return {
                        label: doctor.docName,
                        value: doctor.docId
                    }
                })
                setDoctorList(doctorList1 || [])
                if (doctorList1.length === 1) {
                    setInfo((prev) => {
                        return {
                            ...prev,
                            doctorId: doctorList1[0].value
                        }
                    })
                }
            }
        })
    }, [])

    return (
        <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                {info?.patientId !== "0" ? <Label label="Patient ID" value={info.patientId} /> : null}
                {info?.patientId === "0" ? <DatePicker
                    label="Appointment Date"
                    name="date"
                    placeholderText="DD / MM / YYYY"
                    ref={inputRefs.current[0]}
                    onChange={handleChange}
                    value={info.date}
                    validations={validations.date}
                    disabled={info.patientId !== "0" ? true : false}
                /> : null}
                {info?.patientId === "0" ? <TimePicker
                    label="Appointment Time"
                    placeholder="00 : 00 --"
                    name="time"
                    value={info.time}
                    ref={inputRefs.current[1]}
                    onChange={handleChange}
                    validations={validations.time}
                    disabled={info.patientId !== "0" ? true : false}
                /> : null}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                <TextInput
                    label="Patient Name"
                    placeholder="First Name"
                    name="firstName"
                    value={info.firstName}
                    ref={inputRefs.current[2]}
                    onChange={handleChange}
                    validations={validations.firstName}
                />
                <TextInput
                    placeholder="Middle Name"
                    value={info.middleName}
                    ref={inputRefs.current[3]}
                    name="middleName"
                    onChange={handleChange}
                />
                <TextInput
                    placeholder="Last Name"
                    value={info.lastName}
                    ref={inputRefs.current[4]}
                    name="lastName"
                    onChange={handleChange}
                    validations={validations.lastName}
                />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-9 col-span-2">
                    <Dropdown
                        label="Gender"
                        placeholder="Select Gender"
                        options={genderOptions}
                        value={info.gender}
                        ref={inputRefs.current[5]}
                        onChange={(value) => {
                            handleChange("gender", value.value)
                        }}
                        validations={validations.gender}
                    />
                    <TextInput
                        label="Age"
                        placeholder="Years"
                        name="age"
                        value={info.age}
                        ref={inputRefs.current[6]}
                        onChange={handleChange}
                        validations={validations.age}
                    />
                    {info?.patientId === "0" && <Dropdown
                        label="Priority"
                        placeholder="Select Priority"
                        value={info.priority}
                        options={priorityOptions}
                        ref={inputRefs.current[7]}
                        onChange={(value) => {
                            handleChange("priority", value.value)
                        }}
                        validations={validations.priority}
                    />}
                    <Dropdown
                        label="Marital Status"
                        placeholder="Select Marital Status"
                        options={maritalStatusOptions}
                        value={info.maritalStatus}
                        ref={inputRefs.current[8]}
                        onChange={(value) => {
                            handleChange("maritalStatus", value.value)
                        }}
                        validations={validations.maritalStatus}
                    />
                    {info?.patientId === "0" && <Dropdown
                        label="Consultant Doctor"
                        placeholder="Select Doctor"
                        options={doctorList}
                        value={doctorList.length === 1 ? doctorList[0].value : info.doctorId}
                        ref={inputRefs.current[9]}
                        onChange={(value) => {
                            handleChange("doctorId", value.value)
                        }}
                        validations={validations.doctorId}
                    />}
                    <TextInput
                        label="Occupation"
                        placeholder="Occupation"
                        value={info.occupation}
                        ref={inputRefs.current[10]}
                        name="occupation"
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <ImageUpload
                        // customStyle={{
                        //     mainDiv: 'justify-center',
                        //     webCam: 'w-full h-40',
                        //     webCamImg: 'w-40 h-40',
                        //     uploadImgMainDiv: 'flex justify-center',
                        //     uploadImg: 'w-40 h-40',
                        //     uploadImgDiv: 'w-40 h-40',
                        // }}
                        // className="mt-7"
                        userType="patient"
                        userId={info.patientId}
                        label="Click to Upload Photo"
                        image={info.profilePicture}
                        onImageUpload={(imagePath, imageName) => {
                            handleChange("profilePicture", imagePath)
                        }}
                    />
                </div>
            </div>
            <div className="flex items-center justify-between mt-10">
                <Button text="Previous" textStyle="mx-5 sm:mx-10" disabled={true} />
                <Button text="Save & Next" textStyle="mx-5 sm:mx-10" onClick={handleNextClick} />
            </div>
        </div>
    )
}

export default PatientInformation