import React, { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WithHeaderSidebar from "../../../layout/withHeaderSidebar";
import WithAuth from "../../../layout/withAuth";
import TextInput from "../../../components/textInput";
import {
  createRole,
  getPoliciesForRoles,
  getRoleById,
  updateRoleById,
} from "../../../services/permissions/roles";
import { getAllPolicy } from "../../../services/permissions/policy";
import Button from "../../../components/button";
import { handleDrawer } from "../../../actions/userActions";
import { connect } from "react-redux";

const AddRole = ({ handleDrawer }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rolesInfo, setRolesInfo] = useState({
    roleName: "",
    roleDescription: "",
  });
  const ref = useRef([createRef(), createRef()]);
  const [functionalities, setFunctionalities] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [checkAllPolicy, setCheckAllPolicy] = useState([])
  const [checkAllFunctionalities, setCheckAllFunctionalities] = useState([])

  const getRoleDetails = () => {
    if (id) {
      getRoleById(id).then((res) => {
        if (res.status === 200) {
          const {
            roleName,
            roleDescription,
            policies: { functionalities },
          } = res.result?.role;
          setRolesInfo({
            roleName,
            roleDescription,
          });
          setFunctionalities(functionalities);
        }
      });
    } else {
      getPoliciesForRoles().then((res) => {
        if (res.status === 200) {
          setFunctionalities(res.result?.functionalities);
        }
      });
    }
  };

  const getPolicies = () => {
    getAllPolicy().then((res) => {
      if (res.status === 200) {
        setPolicies(res.result?.policyList);
      }
    });
  };

  useEffect(() => {
    getPolicies();
    getRoleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddAndEdit = () => {
    const isValid =
      ref.current[0].current.validate() && ref.current[1].current.validate();
    if (!isValid) {
      return;
    }
    if (!id) {
      createRole({
        roleName: rolesInfo.roleName,
        roleDescription: rolesInfo.roleDescription,
        policies: {
          functionalities,
        },
      }).then((res) => {
        if (res.status === 200) {
          navigate("/roles/manage");
        }
      });
    } else {
      updateRoleById({
        roleId: id,
        roleName: rolesInfo.roleName,
        roleDescription: rolesInfo.roleDescription,
        policies: {
          functionalities,
        },
      }).then((res) => {
        if (res.status === 200) {
          navigate("/roles/manage");
        }
      });
    }
  };

  const handleToggle = (functionalityId, moduleId, policyId) => {
    const checkAll = []
    let fullPermissions = 0
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));

    functionalitiesTemp.forEach((functionality) => {
      // increment fullPermissions count if policyId includes
      functionality.modules.forEach((module) => {
        if (module.moduleAssignedPolicy.includes(policyId)) {
          fullPermissions++
        }
      });

      if (functionality.functionalityId === functionalityId) {
        functionality.modules.forEach((module) => {
          if (module.moduleId === moduleId) {
            if (module.roleAssignedPolicy.includes(policyId)) {
              const index = module.roleAssignedPolicy.indexOf(policyId);
              module.roleAssignedPolicy.splice(index, 1);
            } else {
              module.roleAssignedPolicy.push(policyId);
            }
          }
        });
      }
    });

    setFunctionalities(functionalitiesTemp);

    // push policyId into checkall
    functionalitiesTemp.forEach((fun) => {
      fun.modules?.forEach((role) => {
        if (role.roleAssignedPolicy.includes(policyId)) {
          checkAll.push(role.roleAssignedPolicy.includes(policyId))
        }
      })
    })

    // Logic of checkAllPolicy
    if (checkAll.length === fullPermissions) {
      setCheckAllPolicy((prev) => {
        return [...prev, policyId]
      })
    } else {
      setCheckAllPolicy((prev) => {
        return prev.filter(item => item !== policyId)
      })
    }

    // Logic of checkAllFunctionalities
    functionalitiesTemp.forEach((fun) => {
      if (fun.functionalityId === functionalityId) {
        fun.modules?.forEach((module, index) => {
          if (module.moduleId === moduleId) {
            if (module.roleAssignedPolicy.length === module.moduleAssignedPolicy.length) {
              fun.modules[index] = {
                ...module,
                checkAll: true
              };
            } else {
              fun.modules[index] = {
                ...module,
                checkAll: false
              };
            }
          }
        });
        const checked = fun.modules?.every((val) => val.checkAll)
        if (checked) {
          setCheckAllFunctionalities((prev) => {
            return [...prev, fun.functionalityId]
          })
        } else {
          setCheckAllFunctionalities((prev) => {
            return prev.filter(item => item !== fun.functionalityId)
          })
        }
      }
    });

  };

  const toggleAll = (event) => {
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));
    functionalitiesTemp.forEach((functionality) => {
      functionality.modules.forEach((module, index) => {
        if (event.target.checked) {
          module.roleAssignedPolicy = module.moduleAssignedPolicy;
          setCheckAllFunctionalities((prev) => {
            const data = [...prev]
            if (!data.includes(functionality.functionalityId)) {
              data.push(functionality.functionalityId)
            }
            return data
          })

          setCheckAllPolicy((prev) => {
            const data = new Set([...prev]);
            module.moduleAssignedPolicy.forEach((policy) => data.add(policy));
            return [...data];
          });

        } else {
          module.roleAssignedPolicy = [];
          setCheckAllFunctionalities((prev) => {
            return prev.filter(item => item !== functionality.functionalityId)
          })
          setCheckAllPolicy([])
        }
      });
    });
    setFunctionalities(functionalitiesTemp);
  };

  const toggleRow = (event, functionalityId) => {
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));
    functionalitiesTemp.forEach((functionality) => {
      if (functionality.functionalityId === functionalityId) {
        functionality.modules.forEach((module) => {
          if (event.target.checked) {
            module.roleAssignedPolicy = module.moduleAssignedPolicy;
            setCheckAllFunctionalities((prev) => {
              const data = [...prev]
              if (!data.includes(functionalityId)) {
                data.push(functionalityId)
              }
              return data
            })
          } else {
            module.roleAssignedPolicy = [];
            setCheckAllFunctionalities((prev) => {
              return prev.filter(item => item !== functionalityId)
            })
          }
        });
      }
    });
    setFunctionalities(functionalitiesTemp);
  };

  const toggleCol = (event, policyId) => {
    const functionalitiesTemp = JSON.parse(JSON.stringify(functionalities));
    functionalitiesTemp.forEach((functionality) => {
      functionality.modules.forEach((module) => {
        if (
          event.target.checked &&
          module.moduleAssignedPolicy?.includes(policyId) &&
          !module.roleAssignedPolicy.includes(policyId)
        ) {
          module.roleAssignedPolicy.push(policyId);
          setCheckAllPolicy((prev) => {
            const data = [...prev]
            if (!data.includes(policyId)) {
              data.push(policyId)
            }
            return data
          })
        } else if (
          !event.target.checked &&
          module.roleAssignedPolicy.includes(policyId)
        ) {
          const index = module.roleAssignedPolicy.indexOf(policyId);
          module.roleAssignedPolicy.splice(index, 1);
          setCheckAllPolicy((prev) => {
            return prev.filter(item => item !== policyId)
          })
        }
      });
    });
    setFunctionalities(functionalitiesTemp);
  };
  return (
    <div className="h-full">
      <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px] flex items-center md:block gap-5">
        <button className="md:hidden" onClick={handleDrawer}>
          <i class="fa-solid fa-list"></i>
        </button>
        <p className="text-xl md:text-2xl md:leading-6 text-[#404040] font-bold">
          Roles
        </p>
      </div>
      <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
        <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
          <div className="flex flex-row items-center justify-between p-3 sm:p-7 border-b ">
            <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
              {id ? "Edit Role" : "Create Role"}
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 p-3 sm:p-7 sm:pt-5">
            <TextInput
              validations={[
                { type: "required", message: "Please enter role name" },
              ]}
              value={rolesInfo.roleName}
              ref={ref.current[0]}
              label="Role Name"
              onChange={(name, value) => {
                setRolesInfo((prev) => {
                  return {
                    ...prev,
                    roleName: value,
                  };
                });
              }}
            />
            <TextInput
              validations={[
                { type: "required", message: "Please enter role description" },
              ]}
              value={rolesInfo.roleDescription}
              ref={ref.current[1]}
              label="Role description"
              onChange={(name, value) => {
                setRolesInfo((prev) => {
                  return {
                    ...prev,
                    roleDescription: value,
                  };
                });
              }}
            />
            <div className="table-content-wrapper height-58 overflow-auto col-span-2">
              <table striped="true" className="w-full">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }} align="center">
                      <input type="checkbox" onChange={toggleAll} />
                    </th>
                    <th style={{ width: "20%" }} align="left">
                      Functionality
                    </th>
                    <th style={{ width: "15%" }} align="left">
                      Modules
                    </th>
                    {policies.map((policy) => {
                      return (
                        <th style={{ width: "10%" }} key={policy.policyId}>
                          <p>{policy.policyName}</p>
                          <input
                            type="checkbox"
                            checked={checkAllPolicy.includes(policy.policyId)}
                            onChange={(event) =>
                              toggleCol(event, policy.policyId)
                            }
                          />
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {functionalities.map((functionality) => {
                    return functionality.modules.map((module, index) => {
                      return (
                        <tr
                          key={
                            functionality.functionalityId +
                            "_" +
                            module.moduleId
                          }
                        >
                          <td
                            align="center"
                            key={module.moduleId}
                            className="pr-[10px]"
                          >
                            {index === 0 ? (
                              <input
                                type="checkbox"
                                checked={checkAllFunctionalities.includes(functionality.functionalityId)}
                                onChange={(event) =>
                                  toggleRow(
                                    event,
                                    functionality.functionalityId
                                  )
                                }
                              />
                            ) : null}
                          </td>
                          <td>
                            {index === 0 ? functionality.functionalityName : ""}
                          </td>
                          <td>{module.moduleName}</td>
                          {policies.map((policy) => {
                            return (
                              <td
                                align="center"
                                key={policy.policyId}
                                className="pr-[10px]"
                              >
                                {module.moduleAssignedPolicy.includes(
                                  policy.policyId
                                ) ? (
                                  <input
                                    type="checkbox"
                                    checked={module.roleAssignedPolicy.includes(
                                      policy.policyId
                                    )}
                                    onChange={() => {
                                      handleToggle(
                                        functionality.functionalityId,
                                        module.moduleId,
                                        policy.policyId
                                      );
                                    }}
                                  />
                                ) : null}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end col-span-2 mt-3 gap-4">
              <Button text="Save" onClick={handleAddAndEdit} />
              <Button
                text="Cancel"
                onClick={() => {
                  navigate("/roles/manage");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    doctorId: state.user.doctorId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawer: () => dispatch(handleDrawer()),
  };
};

export default WithAuth(WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(AddRole)));
