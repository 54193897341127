import React, { createRef, useEffect, useRef, useState } from "react";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../services/auth";
import { connect } from "react-redux";
import { setUser } from "../../actions/userActions";
import { ReactComponent as EyeIcon } from "../../assets/svgs/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/svgs/eye-slash.svg";
import { getOrganization } from "../../services/organization";

const validations = {
  username: [
    {
      type: "required",
      message: "Enter your user name",
    },
    // ,
    // {
    //   type: "regex",
    //   regex:
    //     "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
    //   message: "Please enter a valid email address.",
    // },
  ],
  password: [
    {
      type: "required",
      message: "Enter your password",
    },
  ],
};

const Login = ({ setUser = () => { } }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const inputRefs = useRef([createRef(), createRef()]);
  const [info, setInfo] = useState({
    username: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {      
      navigate("/dashboard");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (name, value) => {
    setInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleLogin = () => {
    setError("");
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (!isValid) {
      return;
    }
    userLogin(info).then((res) => {
      if (res.status === 200) {
        setUser({
          userDetails: res?.result?.user,
          permission: res?.result?.permission,
          doctorId: res?.result?.doctorId || 0
        });
        getOrganization(1).then((res) => {
          if (res.status === 200) {
            const details = res?.result?.organizationDetails
            localStorage.setItem('organizationDetails', JSON.stringify(details))
          }
        })
        navigate("/dashboard");
      } else {
        setError(res.message);
      }
    });
  };
  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{
        backgroundImage: "url(/images/auth_screens_Background.png)",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex items-center justify-center md:w-[50%] bg-white opacity-90 hidden">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[192px] h-[232px]"
          alt="logo"
        />
      </div>
      <div className="flex flex-col md:w-[50%] h-screen bg-[#f3f3ffe6] items-center sm:justify-center p-5 md:px-10 lg:px-28">
        <img
          src="/images/Pulse_Logo_Auth_Screens.png"
          className="w-[132px] h-[160px] m-5 md:hidden"
          alt="logo"
        />
        <div className="flex flex-col bg-white p-8 sm:px-10 sm:pt-[71px] sm:pb-[75px] w-full rounded-[10px] shadow-2xl">
          <p className="text-2xl leading-7 text-[#000000] font-bold">Sign In</p>
          <TextInput
            label="User Name"
            placeholder="Username"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            containerStyle="mt-[64px]"
            name="username"
            value={info.username}
            onChange={handleChange}
            validations={validations.username}
            ref={inputRefs.current[0]}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                handleLogin()
              }
            }}
          />
          <TextInput
            label="Password"
            placeholder="******"
            inputContainerStyle="bg-[#F3F3FF] border-[0px]"
            name="password"
            value={info.password}
            onChange={handleChange}
            validations={validations.password}
            ref={inputRefs.current[1]}
            type={showPass ? "text" : "password"}
            rightIcon={
              showPass ? (
                <EyeIcon className="h-4" />
              ) : (
                <EyeSlashIcon className="h-4" />
              )
            }
            onRightIconClick={() => {
              setShowPass((prev) => !prev);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                handleLogin()
              }
            }}
            rightIconContainerStyle="flex justify-center items-center h-[36px] w-[36px] bg-trasparent"
          />
          <div className="flex flex-row">
            <input type="checkbox" />
            <p className="text-[#404040] text-sm leading-[17px] font-bold ml-2">
              Remember Password
            </p>
          </div>
          {error ? (
            <span className="text-sm text-red-500 mt-2">{error}</span>
          ) : null}
          <Button
            text="Sign In"
            buttonStyle="py-[9px] mt-[17px] border border-[#B9C1CC] rounded-[3px]"
            onClick={handleLogin}
          />
          <div className="flex w-full bg-white justify-end mt-3">
            <p
              className="text-right text-[#00ADEE] cursor-pointer"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot Password?
            </p>
          </div>
          <div className="h-[1px] w-full bg-[#707070] mt-10" />
          <p className="text-center text-[#404040] text-sm leading-[17px] mt-[14px]">
            Don't have account?{" "}
            <span
              className="text-[#00ADEE] cursor-pointer"
              onClick={() => {
                navigate("/register");
              }}
            >
              Create Account
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (data) => {
      dispatch(setUser(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(Login);
