// import React, { useEffect, useMemo, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import { ReactComponent as User } from "../assets/svgs/user-alt.svg"
// import { uploadFile } from "../services/common";

// const activeStyle = {
//   borderColor: "#2196f3",
// };
// const acceptStyle = {
//   borderColor: "#00e676",
// };
// const rejectStyle = {
//   borderColor: "#ff1744",
// };

// // function getBase64(file, cb) {
// //   let reader = new FileReader();
// //   reader.readAsDataURL(file);
// //   reader.onload = function () {
// //     cb(reader.result);
// //   };
// //   reader.onerror = function (error) {
// //   };
// // }

// const ImageUpload = ({
//   className = "",
//   onImageUpload = () => { },
//   image = null,
//   userId,
//   userType = "",
//   label = "Click to Upload Photo",
//   disabled = false,
//   fileType="profilePhoto"
// }) => {
//   const [acceptedImage, setAcceptedImage] = useState({});
//   useEffect(() => {
//     setAcceptedImage(image);
//   }, [image]);
//   const {
//     getRootProps,
//     getInputProps,
//     isDragActive,
//     isDragAccept,
//     isDragReject,
//   } = useDropzone({
//     noClick: false,
//     noKeyboard: true,
//     multiple: true,
//     maxFiles: 1,
//     onDrop: (acceptedFiles) => {
//       acceptedFiles.forEach((file) => {
//         handleSaveFile(file);
//       });
//     },
//   });
//   const handleSaveFile = async (file) => {
//     let formData = new FormData()
//     formData.append('file', file)
//     formData.append("userType", userType)
//     formData.append("fileType", fileType)
//     if (userId !== "0") {
//       formData.append("id", userId)
//     }
//     uploadFile(formData).then(res => {
//       if (res.status === 200) {
//         const { imageName, imagePath } = res?.result;
//         onImageUpload(imagePath, imageName);
//         setAcceptedImage(imagePath);
//       }
//     })
//   };
//   const style = useMemo(
//     () => ({
//       // ...baseStyle,
//       ...(isDragActive ? activeStyle : {}),
//       ...(isDragAccept ? acceptStyle : {}),
//       ...(isDragReject ? rejectStyle : {}),
//     }),
//     [isDragActive, isDragAccept, isDragReject]
//   );
//   return (
//     <div {...getRootProps({ style })} className={`w-full h-full ${className} ${disabled ? "pointer-events-none" : ""}`}>
//       <div className="flex flex-col w-full h-full border rounded-[5px] border-[#CED4DA] justify-between">
//         {acceptedImage ?
//           typeof acceptedImage === "string" && acceptedImage.split(".")[acceptedImage.split(".").length-1] === "pdf" ?
//             <div className="w-full h-full flex justify-center items-center">
//               <a href={acceptedImage}>
//                 <i className="far fa-file-pdf fa-10x"></i>
//               </a>
//             </div>
//           :
//             <img src={acceptedImage} className="h-[200px]" alt="person" />
//         : (
//           <div className="flex justify-center items-center h-full w-full">
//             <User height={94} width={82} fill="#CED4DA" />
//           </div>
//         )}
//         <div className="flex items-center justify-center rounded-[5px] bg-[#C5C5C5] py-[6px]">
//           <p className="text-center text-sm leading-[21px] text-[#404040]">
//             {label}
//           </p>
//         </div>
//       </div>
//       <input {...getInputProps()} />
//     </div>
//   );
// };

// export default ImageUpload;




import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as User } from "../assets/svgs/user-alt.svg";

import { uploadFile } from "../services/common";
import Webcam from "react-webcam";
import Button from "./button";
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};

const ImageUpload = ({
  className = "",
  onImageUpload = () => { },
  image = null,
  userId,
  userType = "",
  label = "Click to Upload Photo",
  disabled = false,
  fileType = "profilePhoto",
}) => {
  const [useWebcam, setUseWebcam] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const [acceptedImage, setAcceptedImage] = useState({});
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [convertedFile, setConvertedFile] = useState(null);

  useEffect(() => {
    setAcceptedImage(image);
  }, [image]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        handleSaveFile(file);
      });
    },
  });

  const handleSaveFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("userType", userType);
    formData.append("fileType", fileType);
    if (userId !== "0") {
      formData.append("id", userId);
    }
    uploadFile(formData).then((res) => {
      if (res.status === 200) {
        const { imageName, imagePath } = res?.result;
        onImageUpload(imagePath, imageName);
        setAcceptedImage(imagePath);
      }
    });
  };

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const byteString = atob(imageSrc.split(",")[1]);
      const mimeString = imageSrc.split(",")[0].split(":")[1].split(";")[0];

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: mimeString });

      const file = new File([blob], "captured_image.jpeg", {
        type: mimeString,
      });

      setConvertedFile(file);
      setCapturedImage(imageSrc);
    }
  };

  const retake = () => {
    setConvertedFile(null);
    setCapturedImage(null);
  };

  return (
    <>
      {
        disabled ? null : (
          <div className="flex mt-0 gap-2 justify-center">
            {/* <Button
          enabledBgColor={`${!useWebcam ? "bg-white" : ""}`}
          buttonStyle={`h-10 ${!useWebcam ? "border-solid border-2" : ""}`}
          textColor={`${!useWebcam ? "text-[#80509F]" : ""}`}
          onClick={() => {
            setUseWebcam(true);
          }}
          // text="Use Webcam"
          icon={}
        >          
        </Button> */}
            <button
              className={`p-2 h-10 w-10 border border-gray-500 rounded-full hover:bg-[#80509F] hover:text-white ${useWebcam ? 'bg-[#80509F] text-white' : ''}`}
              onClick={() => {
                setUseWebcam(true);
              }}>
              <span className="h-5 w-5">
                <i class="fa-solid fa-camera"></i>
              </span>
              {/* <Camera className="h-5" /> */}
            </button>
            <button
              className={`p-2 h-10 w-10 border border-gray-500 rounded-full hover:bg-[#80509F] hover:text-white ${useWebcam ? '' : 'bg-[#80509F] text-white'}`}
              onClick={() => {
                setUseWebcam(false);
              }}>
              <span className="h-5 w-5">
                <i class="fa-solid fa-image"></i>
              </span>
            </button>
            {/* <Button
          enabledBgColor={`${useWebcam ? "bg-white" : ""}`}
          buttonStyle={`h-10 ${useWebcam ? "border-solid border-2" : ""}`}
          textColor={`${useWebcam ? "text-[#80509F]" : ""}`}
          onClick={() => {
            setUseWebcam(false);
          }}
          text="Upload Photo"
        /> */}
          </div>
        )
      }

      {useWebcam ? (
        <>
          <div className="mt-2 flex justify-center">
            {!capturedImage ? (
              <Webcam
                audio={false}
                ref={webcamRef}
                // height={20}
                // width={82}
                screenshotFormat="image/jpeg"
                className="w-full h-40"
              />
            ) : (
              <img
                src={capturedImage}
                alt="Captured"
                className="w-40 h-40 rounded-full shadow-lg"
              />
            )}

          </div>

          <div className="mt-2 flex gap-3 justify-center">
            {!capturedImage ? (
              <Button onClick={capture} text="Capture Photo" />
            ) : (
              <>
                <Button
                  onClick={retake}
                  disabled={isUploaded}
                  text="Retake Photo"
                />
                <Button
                  disabled={isUploaded}
                  onClick={() => {
                    handleSaveFile(convertedFile);
                    setIsUploaded(true);
                    setUseWebcam(false);
                  }}
                  text="Upload Photo"
                />
              </>
            )}
          </div>
        </>
      ) : (
        <div
          {...getRootProps({ style })}
          className={`w-full h-full flex justify-center ${className} ${disabled ? "pointer-events-none" : ""
            }`}
        >
          <div className="flex flex-col w-40 h-40 border rounded-full border-[#CED4DA] justify-between mt-2">
            {acceptedImage ? (
              typeof acceptedImage === "string" &&
                acceptedImage.split(".")[acceptedImage.split(".").length - 1] ===
                "pdf" ? (
                <div className="w-full h-full flex justify-center items-center">
                  <a href={acceptedImage}>
                    <i className="far fa-file-pdf fa-10x"></i>
                  </a>
                </div>
              ) : (
                <img src={acceptedImage} className="h-40 w-40 rounded-full" alt="person" />
              )
            ) : (
              <div className="flex justify-center items-center h-full w-full">
                <User height={94} width={82} fill="#CED4DA" />
              </div>
            )}
            {/* <div className="flex items-center justify-center rounded-[5px] bg-[#C5C5C5] py-[6px]">
              <p className="text-center text-sm leading-[21px] text-[#404040]">
                {label}
              </p>
            </div> */}
          </div>
          <input {...getInputProps()} />
        </div>
      )}
    </>
  );
};

export default ImageUpload;
// {/* <div className={`flex mt-0 gap-2 justify-center`}>
// {/* <Button
//   enabledBgColor={`${!useWebcam ? "bg-white" : ""}`}
//   buttonStyle={`h-10 ${!useWebcam ? "border-solid border-2" : ""}`}
//   textColor={`${!useWebcam ? "text-[#80509F]" : ""}`}
//   onClick={() => {
//     setUseWebcam(true);
//   }}
//   // text="Use Webcam"
//   icon={}
// >          
// </Button> */}
// <button
//   className={`p-2 border border-gray-500 rounded-full hover:bg-[#80509F] ${useWebcam ? 'bg-[#80509F]' : ''}`}
//   onClick={() => {
//     setUseWebcam(true);
//   }}>
//   <Camera className="h-5" />
// </button>
// <button
//   className={`p-2 border border-gray-500 rounded-full hover:bg-[#80509F] ${useWebcam ? '' : 'bg-[#80509F]'}`}
//   onClick={() => {
//     setUseWebcam(false);
//   }}>
//   <Image className="h-5" />
// </button>
// {/* <Button
//   enabledBgColor={`${useWebcam ? "bg-white" : ""}`}
//   buttonStyle={`h-10 ${useWebcam ? "border-solid border-2" : ""}`}
//   textColor={`${useWebcam ? "text-[#80509F]" : ""}`}
//   onClick={() => {
//     setUseWebcam(false);
//   }}
//   text="Upload Photo"
// /> */}
// </div>

// {useWebcam ? (
// <>
//   <div className="mt-2 flex justify-center">
//     {!capturedImage ? (
//       <Webcam
//         audio={false}
//         ref={webcamRef}
//         // height={20}
//         // width={82}
//         screenshotFormat="image/jpeg"
//         className={`${customStyle?.webCam ? customStyle?.webCam : 'w-full h-full'}`}
//       />
//     ) : (
//       <img
//         src={capturedImage}
//         alt="Captured"
//         className={`${customStyle?.webCamImg ? customStyle?.webCamImg : 'w-full h-full'} rounded-full shadow-lg`}
//       />
//     )}

//   </div>

//   <div className="mt-2 flex gap-3 justify-center">
//     {!capturedImage ? (
//       <Button onClick={capture} text="Capture Photo" />
//     ) : (
//       <>
//         <Button
//           onClick={retake}
//           disabled={isUploaded}
//           text="Retake Photo"
//         />
//         <Button
//           disabled={isUploaded}
//           onClick={() => {
//             handleSaveFile(convertedFile);
//             setIsUploaded(true);
//             setUseWebcam(false);
//           }}
//           text="Upload Photo"
//         />
//       </>
//     )}
//   </div>
// </>
// ) : (
// <div
//   {...getRootProps({ style })}
//   className={`w-full h-full flex ${customStyle?.uploadImgMainDiv ? customStyle?.uploadImgMainDiv : ''} ${className} ${disabled ? "pointer-events-none" : ""
//     }`}
// >
//   <div className={`flex flex-col ${customStyle?.uploadImgDiv ? customStyle?.uploadImgDiv : 'w-full h-full'} border rounded-full border-[#CED4DA] justify-between mt-2`}>
//     {acceptedImage ? (
//       typeof acceptedImage === "string" &&
//         acceptedImage.split(".")[acceptedImage.split(".").length - 1] ===
//         "pdf" ? (
//         <div className="w-full h-full flex justify-center items-center">
//           <a href={acceptedImage}>
//             <i className="far fa-file-pdf fa-10x"></i>
//           </a>
//         </div>
//       ) : (
//         <img src={acceptedImage} className={`${customStyle?.uploadImg ? customStyle?.uploadImg : 'w-full h-full'} rounded-full`} alt="person" />
//       )
//     ) : (
//       <div className="flex justify-center items-center h-full w-full">
//         <User height={94} width={82} fill="#CED4DA" />
//       </div>
//     )}

//   </div>
//   <input {...getInputProps()} />
// </div>
// )} */}