// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7MKRoGSFX4MZ-GXz7rJb7pd_owhhFLWw",
  authDomain: "pulseemr.firebaseapp.com",
  projectId: "pulseemr",
  storageBucket: "pulseemr.appspot.com",
  messagingSenderId: "381704480366",
  appId: "1:381704480366:web:e9ad21952334aae4f5287f",
  measurementId: "G-61DMRFYDZX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app)
export { db };