import axiosInterceptors from "../axiosInterceptor/axiosInterceptor";
import { organizationUrl } from "../config/apiConfig";


export const getOrganization = (id) => {
    return axiosInterceptors()
    .get(organizationUrl + `/getOrganization/${id}`)
    .then((res) => res);
}

export const addOrganization = (data) => {
    return axiosInterceptors()
    .post(organizationUrl + "/addOrganization", data)
    .then((res) => res);
}

export const updateOrganization = (id,data) => {
    return axiosInterceptors()
    .patch(organizationUrl + `/addOrganization/${id}`, data)
    .then((res) => res);
}
