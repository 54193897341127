import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as HomeIcon } from "../assets/svgs/home-alt.svg";
import { ReactComponent as NurseIcon } from "../assets/svgs/user-nurse.svg";
import { ReactComponent as UsersIcon } from "../assets/svgs/users.svg";
import { ReactComponent as PostalIcon } from "../assets/svgs/box-alt.svg";
import { ReactComponent as AmbulanceIcon } from "../assets/svgs/ambulance.svg";
import { ReactComponent as PhoneIcon } from "../assets/svgs/phone-rotary.svg";
import { ReactComponent as GridIcon } from "../assets/svgs/grid-add.svg";
import { ReactComponent as UserShield } from "../assets/svgs/user-shield.svg";
import { ReactComponent as UserInjured } from "../assets/svgs/user-injured.svg";
import { ReactComponent as User } from "../assets/svgs/user-alt.svg";
import { ReactComponent as AngleUp } from "../assets/svgs/angle-up.svg";
import { ReactComponent as WatchIcon } from "../assets/svgs/watch.svg";
import { ReactComponent as Report } from "../assets/svgs/file-chart-pie.svg";
import { ReactComponent as PeopleGroup } from "../assets/svgs/people-group-solid.svg";
import { ReactComponent as Virus } from "../assets/svgs/virus-covid-solid.svg";

import Drawer from "@mui/material/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { handleDrawer } from "../actions/userActions";

const sidebarOptionsTemp = [
  {
    label: "Dashboard",
    icon: HomeIcon,
    navigatesTo: "/dashboard",
  },
  {
    label: "Patient",
    icon: GridIcon,
    permission: "Patient",
    subOptions: [
      {
        label: "Registration",
        navigatesTo: "/patients/register",
        permission: "Patient Registration",
      },
      {
        label: "Manage",
        navigatesTo: "/patients/manage",
        permission: "Manage Patient",
      },
      {
        label: "Patient History",
        navigatesTo: "/patients/historys",
        permission: "Manage Patient",
      },
    ],
  },
  {
    label: "OPD Appointment",
    icon: NurseIcon,
    navigatesTo: "/opd-appointment/manage",
    extraRoutes: ["/opd-appointment/add"],
    permission: "OPD Appointment",
  },
  {
    label: "OPD Out",
    icon: UserInjured,
    navigatesTo: "/opd-out/manage",
    prtmission: "OPD Out"
  },
  {
    label: "Disease",
    icon: Virus,
    navigatesTo: "/disease/manage",
    permission: "Disease"
  },
  {
    label: "Reminders",
    icon: WatchIcon,
    permission: "Reminders",
    subOptions: [
      {
        label: "Patient",
        navigatesTo: "/reminders/patient",
        permission: "Patient Reminders",
      }
    ]
  },
  // {
  //   label: "Visitor's Book",
  //   icon: UsersIcon,
  //   navigatesTo: "/visitors-book/manage",
  //   extraRoutes: ["/visitors-book/add"],
  //   permission: "Visitor's Book",
  // },
  // {
  //   label: "Postal",
  //   icon: PostalIcon,
  //   navigatesTo: "/postal/manage",
  //   extraRoutes: ["/postal/add"],
  //   permission: "Postal",
  // },
  // {
  //   label: "Ambulance Log Book",
  //   icon: AmbulanceIcon,
  //   navigatesTo: "/ambulance-log-book/manage",
  //   extraRoutes: ["/ambulance-log-book/add"],
  //   permission: "Ambulance Log Book",
  // },
  // {
  //   label: "Phone Call Book",
  //   icon: PhoneIcon,
  //   navigatesTo: "/phone-call-book/manage",
  //   extraRoutes: ["/phone-call-book/add"],
  //   permission: "Phone Call Book",
  // },
  {
    label: "Reports",
    icon: Report,
    permission: "Reports",
    subOptions: [
      {
        label: "OPD Report",
        navigatesTo: "/reports/opd",
        permission: "OPD Report"
      },
      {
        label: "Disease Report",
        navigatesTo: "/reports/disease",
        permission: "Disease Report"
      }
    ]
  },
  {
    label: "Permissions",
    icon: UserShield,
    permission: "Permissions",
    subOptions: [
      {
        label: "Roles",
        navigatesTo: "/roles/manage",
        extraRoutes: ["/roles/add"],
        permission: "Roles",
      },
      {
        label: "Functionality",
        navigatesTo: "/functionality/manage",
        permission: "Functionality",
      },
      {
        label: "Modules",
        navigatesTo: "/modules/manage",
        extraRoutes: ["/modules/add"],
        permission: "Modules",
      },
      {
        label: "Policy",
        navigatesTo: "/policy/manage",
        permission: "Policy",
      },
    ],
  },
  {
    label: "Users",
    icon: User,
    navigatesTo: "/users/manage",
    extraRoutes: ["/users/add"],
    permission: "Users",
  },
  {
    label: "Organization Menu",
    icon: PeopleGroup,
    navigatesTo: "/organization",
    permission: "Organization",
  },
];

const getIndexes = (sidebarOptions) => {
  let indexes = {};
  sidebarOptions?.forEach((option, index) => {
    if (option) {
      if (option?.subOptions) {
        option?.subOptions?.forEach((subOption, subIndex) => {
          indexes[subOption?.navigatesTo] = [index, subIndex];
          subOption?.extraRoutes?.forEach((route) => {
            indexes[route] = [index, subIndex];
          });
        });
      } else {
        indexes[option?.navigatesTo] = [index];
        option?.extraRoutes?.forEach((route) => {
          indexes[route] = [index];
        });
      }
    }
  });
  return indexes;
};

const getParseOptions = (userPermissions) => {
  let sidebarOptions = [];
  sidebarOptionsTemp?.forEach((option) => {
    if (option.permission) {
      if (userPermissions[option?.permission]?.includes("Show Menu")) {
        if (option?.subOptions) {
          let optionTemp = {};
          optionTemp = {
            label: option.label,
            icon: option.icon,
            navigatesTo: option.navigatesTo,
            extraRoutes: option.extraRoutes,
            subOptions: [],
          };
          option?.subOptions?.forEach((item) => {
            if (item.permission) {
              if (userPermissions[item.permission]?.includes("Show Menu")) {
                optionTemp.subOptions.push(item);
              }
            } else {
              optionTemp.subOptions.push(item);
            }
          });
          sidebarOptions.push(optionTemp);
        } else {
          sidebarOptions.push(option);
        }
      }
    } else {
      sidebarOptions.push(option);
    }
  });
  return sidebarOptions;
};

const CustomDrawer = ({
  userDetails,
  userPermissions,
  open,
  openMobileDrawer,
  handleDrawer,
  setSidebarCollapsed = () => { },
}) => {
  const ImgIcon = () => {
    return <img src="/images/person.jpeg" className="h-[24px] w-[24px] rounded-[4px] md:rounded-[10px]" alt="user pic" />
  }
  const userMenu = {
    label: `${userDetails?.firstName + " " + userDetails?.lastName} (${userDetails?.roleName})`,
    icon: ImgIcon,
    subOptions: [
      {
        label: "Logout",
        navigatesTo: "/logout"
      },
      {
        label: "Profile",
        navigatesTo: "/edit-profile"
      }
    ]
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [expandOptions, setExpandOptions] = useState(false);
  const [expandOptionsIndex, setExpandOptionsIndex] = useState("");
  const sidebarOptions =
    userDetails.roleName === "Owner"
      ? [userMenu, ...sidebarOptionsTemp]
      : [userMenu, ...getParseOptions(userPermissions)];
  const indexes = getIndexes(sidebarOptions);

  useEffect(() => {
    const index = indexes[location.pathname]?.[0];
    if (sidebarOptions[index]?.subOptions) {
      setExpandOptions(true);
      setExpandOptionsIndex(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Drawer
      open={openMobileDrawer}
      onClose={() => {
        handleDrawer()
        // setSidebarCollapsed(false);
      }}
      style={{
        width: "60%",
        flexShrink: 0,
      }}
      classes={{
        paper: "w-[60%]",
      }}
      className="md:hidden"
    >
      <div className="flex flex-col my-[30px]">
        {sidebarOptions?.map((option, index) => {
          if (option === null) {
            return null;
          }
          const { icon: Icon, navigatesTo, label, subOptions } = option || {};
          const urlIndexes = indexes[location.pathname];

          return (
            <Fragment key={index}>
              <div
                className={`group flex flex-row items-center justify-between py-[3px] pl-4 cursor-pointer ${urlIndexes?.[0] === index ? "bg-[#F1F0FF]" : ""
                  } hover:bg-[#F1F0FF]`}
                onClick={() => {
                  if (navigatesTo) {
                    navigate(navigatesTo);
                  } else {
                    if (expandOptionsIndex !== index) {
                      setExpandOptionsIndex(index);
                    } else {
                      setExpandOptions((prev) => !prev);
                    }
                  }
                }}
              >
                <div className={`flex flex-row items-center justify-center`}>
                  <Icon
                    fill={urlIndexes?.[0] === index ? "#80509F" : "#000000"}
                    height={24}
                    width={24}
                  />

                  <p
                    className={`text-sm leading-[17px] font-semibold ml-3 ${urlIndexes?.[0] === index ? "text-[#80509F]" : ""
                      }`}
                  >
                    {label}
                  </p>
                </div>
                <div className="flex items-center">
                  {option?.subOptions ? (
                    expandOptions && expandOptionsIndex === index ? (
                      <AngleUp height={24} width={24} className="mr-2" />
                    ) : (
                      <AngleUp
                        height={24}
                        width={24}
                        className="mr-2 rotate-180"
                      />
                    )
                  ) : null}
                  <div
                    className={`${urlIndexes?.[0] === index
                      ? "bg-[#80509F]"
                      : "group-hover:bg-[#80509F]"
                      }  w-[5px] h-[39px]  rounded-l-[5px]`}
                  />
                </div>
              </div>
              {expandOptions && expandOptionsIndex === index ? (
                <div className="flex flex-col px-10">
                  {subOptions?.map((option, subIndex) => {
                    return (
                      <div
                        key={subIndex}
                        className="flex py-3 cursor-pointer"
                        onClick={() => {
                          navigate(option.navigatesTo);
                        }}
                      >
                        <p
                          className={`text-sm leading-[17px] font-semibold ml-3 ${urlIndexes?.[0] === index &&
                            urlIndexes?.[1] === subIndex
                            ? "text-[#80509F]"
                            : ""
                            }`}
                        >
                          {option.label}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </Fragment>
          );
        })}
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.permission,
    userDetails: state.user.userDetails,
    openMobileDrawer: state.user.openMobileDrawer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawer: () => dispatch(handleDrawer()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomDrawer);
