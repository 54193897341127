import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import WithAuth from "../../../layout/withAuth";
import WithHeaderSidebar from "../../../layout/withHeaderSidebar";
import SearchBox from "../../../components/searchbox";
import FormStepper from "../../../components/formStepper";
import AppointmentInformation from "./appointmentInformation";
import PaymentInformation from "./paymentInformation";
import InsuranceInfo from "./insuranceInfo";
import { getPatientById, searchPatientByName } from "../../../services/patient";
import { getOpdAppointmentById } from "../../../services/opdAppointment";
import { getPatientLastInsuranceDetail } from "../../../services/insuranceDetail";
import axios from "axios";
import axiosInterceptors from "../../../axiosInterceptor/axiosInterceptor";

const steps = [
    { label: "Appointment Information" },
    { label: "Insurance Information" },
    { label: "Payment Information" },
];

const AppointmentRegistration = (props) => {
    const { id } = useParams();
    const [searchKey, setSearchKey] = useState("");
    const [searchList, setSearchList] = useState([]);
    const [regInfo, setRegInfo] = useState({});
    const [info, setInfo] = useState(null);
    const [insuranceScreen, setInsuranceScreen] = useState(0);

    const handleSaveInfo = (info) => {
        setRegInfo((prev) => {
            return {
                ...prev,
                ...info
            }
        });
    }
    const getPatientDetails = (id) => {
        getPatientById(id).then((res) => {
            if (res.status === 200) {
                const patientDetails = res?.result?.patientDto;
                const {
                    patientId,
                    firstName,
                    lastName,
                    middleName,
                    primaryContactNumber,
                    doctorId,
                    priority,
                    profilePicture,
                    age,
                } = patientDetails;
                setInfo((prev) => {
                    return {
                        ...prev,
                        patientId,
                        firstName,
                        lastName,
                        middleName,
                        primaryContactNumber,
                        doctorId,
                        priority,
                        profilePicture,
                        age
                    };
                });
            }
        });
        getPatientLastInsuranceDetail(id).then((res) => {
            if (res.status === 200) {
                const data = res?.result?.lastInsuranceDetail;
                if (data !== null) {
                    setInfo((prev) => {
                        return {
                            ...prev,
                            insuranceDetails: {
                                ...data,
                                policyExpiryDate: new Date(data.policyExpiryDate)
                            },

                        }
                    })
                }
            }
        });
    };

    const getPatientDetailsForEdit = (id) => {
        getPatientById(id).then((res) => {
            if (res.status === 200) {
                const patientDetails = res?.result?.patientDto;
                const {
                    patientId,
                    firstName,
                    lastName,
                    middleName,
                    primaryContactNumber,
                    profilePicture,
                } = patientDetails;
                setInfo((prev) => {
                    return {
                        ...prev,
                        patientId,
                        firstName,
                        lastName,
                        middleName,
                        primaryContactNumber,
                        profilePicture,
                    };
                });
            }
        });
    };
    
    // const getBase64FromImageUrl = async (imageUrl) => {
    //     if (imageUrl) {
    //         try {
    //             const axiosInstance = axiosInterceptors();
    //             const response = await axiosInstance.get(imageUrl, {
    //                 responseType: 'blob',
    //             });
    
    //             const blob = response.data; 
    
    //             return new Promise((resolve) => {
    //                 const reader = new FileReader();
    //                 reader.onloadend = () => resolve(reader.result);
    //                 reader.readAsDataURL(blob);
    //             });
    //         } catch (error) {
    //             console.error("Error fetching or processing the image:", error);
    //             throw error;
    //         }
    //     }
    // };
    

    useEffect(() => {
        if (id) {
            getOpdAppointmentById(id).then((res) => {
                if (res.status === 200) {
                    const details = res?.result?.opdAppointmentResponseDto;
                    const {
                        appointmentId,
                        appointmentDate,
                        patientId,
                        appointmentTime,
                        priority,
                        doctorId,
                        liveConsultant,
                        insuranceDetailsJson,
                        feesDetailsJson,
                        insuranceDetails,
                        fees,
                        displayOrder,
                        patientAge,
                        remarks
                    } = details;
                    setInfo((prev) => {
                        return {
                            ...prev,
                            appointmentId,
                            appointmentDate: new Date(appointmentDate),
                            appointmentTime,
                            priority,
                            doctorId,
                            liveConsultant,
                            insuranceDetailsJson,
                            feesDetailsJson,
                            insuranceDetails,
                            fees,
                            displayOrder,
                            patientAge,
                            remarks
                        };
                    });
                    getPatientDetailsForEdit(patientId);
                } else {
                    setInfo(null);
                }
            });
        }
    }, [id]);
    useEffect(() => {
        if (searchKey) {
            const data = `searchKey=${searchKey}`;
            // getOpdAppointmentsListPage(data).then((res) => {
            //   if (res.status === 200) {
            //     setSearchList(res?.result?.opdAppointmentListPage);
            //   }
            // });
            searchPatientByName(data).then((res) => {
                if (res.status === 200) {
                    setSearchList(res?.result?.patientDtos);
                }
            });
        }
    }, [searchKey]);

    return (
        <div className="flex flex-col">
            <div className="p-5 sm:pl-8 sm:pt-7 sm:pb-[23px]">
                <p className="text-xl sm:text-2xl sm:leading-6 text-[#404040] font-bold">
                    OPD Appointment
                </p>
            </div>
            <div className="flex flex-col lg:flex-row bg-[#F7F8F9] pt-[18px] p-5 sm:px-7 sm:pb-9 gap-[22px]">
                {
                    !(typeof id !== "undefined" && id !== null && id !== "") &&
                    <div className="flex flex-col bg-[#FFFFFF] rounded-[15px] lg:w-[22%] shadow-[0px_9px_20px] shadow-[#2E235E1A]">
                        <div className="p-3 lg:px-[30px] border-b">
                            <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
                                Search Patient
                            </p>
                        </div>
                        <div className="p-5 lg:px-[30px]">
                            <SearchBox
                                name="searchKey"
                                onChange={(name, value) => {
                                    setSearchKey(value);
                                }}
                            />
                            <div className="pt-[15px]">
                                {searchList?.map((item) => {
                                    return (
                                        <p
                                            key={item.patientId}
                                            className="text-[14px] leading-[30px] font-light text-[#404040] cursor-pointer"
                                            onClick={() => {
                                                getPatientDetails(item.patientId);
                                            }}
                                        >
                                            {item.patientId} -{" "} {item.firstName + " " + item.middleName + " " + item.lastName}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                }
                <div className={`flex flex-col bg-[#FFFFFF] rounded-[15px] ${!(typeof id !== "undefined" && id !== null && id !== "") ? "lg:w-[78%]" : "lg:w-full"} shadow-[0px_9px_20px] shadow-[#2E235E1A]`}>
                    <div className="p-3 lg:px-[30px] border-b">
                        <p className="text-base md:text-xl md:leading-6 text-[#404040] font-bold">
                            OPD Appointment
                        </p>
                    </div>
                    <FormStepper steps={steps}>
                        <AppointmentInformation info={info} saveInfo={handleSaveInfo} insuranceScreen={insuranceScreen} setInsuranceScreen={setInsuranceScreen} />
                        <InsuranceInfo info={info} saveInfo={handleSaveInfo} insuranceScreen={insuranceScreen} setInsuranceScreen={setInsuranceScreen} />
                        <PaymentInformation info={info} regInfo={regInfo} handleSaveInfo={handleSaveInfo} insuranceScreen={insuranceScreen} setInsuranceScreen={setInsuranceScreen} />
                    </FormStepper>
                </div>
            </div>
        </div>
    )
}

export default WithAuth(WithHeaderSidebar(AppointmentRegistration));
