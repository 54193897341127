import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Register from "./screen/auth/register";
import SecurityQuestions from "./screen/auth/security-questions";
import Login from "./screen/auth/login";
import ForgotPassword from "./screen/auth/forgot-password";
import ResetPassword from "./screen/auth/reset-password";
import ForgotMethodSelect from "./screen/auth/forgot-method-select";
import VerifyOtp from "./screen/auth/verify-otp";
import AccountCreated from "./screen/auth/account-created";
import PaymentMethod from "./screen/auth/payment-method";
import Dashboard from "./screen/dashboard";
import PhoneCallBook from "./screen/phoneCallBook/manage";
import AmbulanceLogBook from "./screen/ambulanceLogBook/manage";
import VisitorsBook from "./screen/visitorsBook/manage";
import OpdAppointment from "./screen/opdAppointment/manage";
import OpdOut from "./screen/opdOut/manage";
import Postal from "./screen/postal/manage";
import RegisterPostal from "./screen/postal/registerPostal";
import RegisterPhoneCall from "./screen/phoneCallBook/register";
import AmbulanceLog from "./screen/ambulanceLogBook/register";
import NewVisitRegistration from "./screen/visitorsBook/newVisitRegistration";
import NewOpdAppointment from "./screen/opdAppointment/newOpdAppointment/index";
import PatientRegistration from "./screen/patient/patientRegistration";
import Loader from "./components/loader";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import ManagePatient from "./screen/patient/manage";
import Roles from "./screen/permissions/roles/manage";
import Functionality from "./screen/permissions/functionality";
import Module from "./screen/permissions/modules/manage";
import Policy from "./screen/permissions/policy";
import CreateModule from "./screen/permissions/modules/createModule";
import AddRole from "./screen/permissions/roles/addRole";
import ManageUsers from "./screen/users/manage";
import AddUser from "./screen/users/addUser";
import EditUser from "./screen/users/editUser";
import PageNotFound from "./screen/pageNotFound";
import NotGranted from "./screen/permissionNotGranted";
// import PatientHistory from "./screen/opdOut/patientHistory";
import PatientHistory2 from "./screen/opdOut/patientHistory2";
import OPDReport from "./screen/reports/opdReport/report";
import DiseaseReport from "./screen/reports/diseaseReport/report";
import Logout from "./screen/userMenu/logout";
import Profile from "./screen/userMenu/profile";
import OpdReportPdf from "./screen/pdf/opdReportPdf";
import ViewPatientHistory from "./screen/patient/viewPatientHistory";
import PatientHistoryList from "./screen/patient/PatientHistoryList";
import PatientReminders from "./screen/reminders/patientReminders";
import AddOrganization from "./screen/organization/addOrganization";
import RxPdf from "./screen/pdf/rxPdf";
import DietPlanPdf from "./screen/pdf/dietPlanPdf";
import Disease from "./screen/disease/manage";
import DiseaseReportPdf from "./screen/pdf/diseaseReportPdf";

const router = createBrowserRouter([
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/opdReportPdf",
    element: <OpdReportPdf />,
  },
  {
    path: "/rxPdf",
    element: <RxPdf />,
  },
  {
    path: "/diseaseReportPdf",
    element: <DiseaseReportPdf />,
  },
  {
    path: "/dietPlanPdf",
    element: <DietPlanPdf />,
  },
  {
    path: "/not-granted",
    element: <NotGranted />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />
  },
  {
    path: "/edit-profile",
    element: <Profile />
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/verify-otp",
    element: <VerifyOtp />,
  },
  {
    path: "/security-questions",
    element: <SecurityQuestions />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/forgot-method-select",
    element: <ForgotMethodSelect />,
  },
  {
    path: "/payment-method",
    element: <PaymentMethod />,
  },
  {
    path: "/account-created",
    element: <AccountCreated />,
  },
  {
    path: "/patients",
    children: [
      {
        path: "manage",
        element: <ManagePatient module="Manage Patient" permission="View" />,
      },
      {
        path: "historys",
        element: <PatientHistoryList module="Patient History" permission="View" />,
      },
      {
        path: "history/:patientId",
        element: <ViewPatientHistory module="Patient History" permission="View" />,
      },
      {
        path: "register",
        element: (
          <PatientRegistration module="Patient Registration" permission="Add" />
        ),
      },
      {
        path: "edit/:id",
        element: (
          <PatientRegistration module="Manage Patient" permission="Edit" />
        ),
      },
    ],
  },
  {
    path: "/reminders",
    children: [
      {
        path: "patient",
        element: <PatientReminders module="Patient Reminders" permission="View" />
      }
    ]

  },
  {
    path: "/opd-out/manage",
    element: <OpdOut module="OPD Out" permission="View" />
    // children: [
    //   {
    //     path: "manage",
    //     element: <OpdOut module="OPD Out" permission="View"/>
    //   }
    // ],
  },
  {
    path: "/reports",
    children: [
      {
        path: "opd",
        element: <OPDReport module="OPD Report" permission="View" />
      },
      {
        path: "disease",
        element: <DiseaseReport module="Disease Report" permission="View" />
      }
    ]
  },
  {
    path: "/patient-history/:id/:opdId",
    element: <PatientHistory2 module="OPD Out" permission="Edit" />
  },
  {
    path: "/patient-history2/:id/:opdId",
    element: <PatientHistory2 module="OPD Out" permission="Edit" />
  },
  {
    path: "/opd-appointment",
    children: [
      {
        path: "manage",
        element: <OpdAppointment module="OPD Appointment" permission="View" />,
      },
      {
        path: "add",
        element: (
          <NewOpdAppointment module="OPD Appointment" permission="Add" />
        ),
      },
      {
        path: "edit/:id",
        element: (
          <NewOpdAppointment module="OPD Appointment" permission="Edit" />
        ),
      },
    ],
  },
  {
    path: "/disease",
    children: [
      {
        path: "manage",
        element: <Disease module="Disease" permission="View" />,
      },
    ],
  },
  {
    path: "/visitors-book",
    children: [
      {
        path: "manage",
        element: <VisitorsBook />,
      },
      {
        path: "add",
        element: <NewVisitRegistration />,
      },
      {
        path: "edit/:id",
        element: <NewVisitRegistration />,
      },
    ],
  },
  {
    path: "/postal",
    children: [
      {
        path: "manage",
        element: <Postal />,
      },
      {
        path: "add",
        element: <RegisterPostal />,
      },
      {
        path: "edit/:id",
        element: <RegisterPostal />,
      },
    ],
  },
  {
    path: "/ambulance-log-book",
    children: [
      {
        path: "manage",
        element: <AmbulanceLogBook />,
      },
      {
        path: "add",
        element: <AmbulanceLog />,
      },
      {
        path: "edit/:id",
        element: <AmbulanceLog />,
      },
    ],
  },
  {
    path: "/phone-call-book",
    children: [
      {
        path: "manage",
        element: <PhoneCallBook />,
      },
      {
        path: "add",
        element: <RegisterPhoneCall />,
      },
      {
        path: "edit/:id",
        element: <RegisterPhoneCall />,
      },
    ],
  },
  {
    path: "/roles",
    children: [
      {
        path: "manage",
        element: <Roles module="Roles" permission="View" />,
      },
      {
        path: "add",
        element: <AddRole module="Roles" permission="Add" />,
      },
      {
        path: "edit/:id",
        element: <AddRole module="Roles" permission="Edit" />,
      },
    ],
  },
  {
    path: "/functionality",
    children: [
      {
        path: "manage",
        element: <Functionality module="Functionality" permission="View" />,
      },
    ],
  },
  {
    path: "/modules",
    children: [
      {
        path: "manage",
        element: <Module module="Modules" permission="View" />,
      },
      {
        path: "add",
        element: <CreateModule module="Modules" permission="Add" />,
      },
      {
        path: "edit/:id",
        element: <CreateModule module="Modules" permission="Edit" />,
      },
    ],
  },
  {
    path: "/policy",
    children: [
      {
        path: "manage",
        element: <Policy module="Policy" permission="View" />,
      },
    ],
  },
  {
    path: "/users",
    children: [
      {
        path: "manage",
        element: <ManageUsers module="Users" permission="View" />,
      },
      {
        path: "add",
        element: <AddUser module="Users" permission="Add" />,
      },
      {
        path: "edit/:id",
        element: <EditUser module="Users" permission="Edit" />,
      },
    ],
  },
  {
    path: "/organization",
    element: <AddOrganization module="Organization" permission="Add" />,
  },  
]);

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Loader />
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
