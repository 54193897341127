import React from "react";
import TextInput from "../../components/textInput";

const validations = {
  orgName: [{ type: "required", message: "Please enter organization name" }],
  orgEmail: [{ type: "required", message: "Please enter organization email" }],
  orgAddress: [{ type: "required", message: "Please enter organization address" }],
};

const OrganizationInfo = ({
  editMode = false,
  organizationInfo,
  inputRefs,
  handleChange = () => { },
  user,
}) => {
  return (
    <>
      <TextInput
        validations={validations.orgName}
        value={organizationInfo.orgName}
        ref={inputRefs.current[0]}
        label="Organization Name"
        name="orgName"
        onChange={handleChange}
        placeholder="Enter organization name"
      />
      <TextInput
        validations={validations.orgEmail}
        value={organizationInfo.orgEmail}
        ref={inputRefs.current[1]}
        label="Organization Email"
        name="orgEmail"
        onChange={handleChange}
        placeholder="Enter organization email"
      />
      <TextInput
        validations={validations.orgAddress}
        value={organizationInfo.orgAddress}
        ref={inputRefs.current[2]}
        label="Organization address"
        name="orgAddress"
        onChange={handleChange}
        placeholder="Enter organization address"
      />
    </>
  );
};

export default OrganizationInfo;
