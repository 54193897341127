import React, { createRef, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import WithAuth from "../../layout/withAuth";
import WithHeaderSidebar from "../../layout/withHeaderSidebar";
import { getUserById, editProfile } from "../../services/user";
import { handleDrawer, setUser } from "../../actions/userActions";
import ImageUpload from "../../components/imageUpload";

const validations = {
    firstName: [{ type: "required", message: "Please enter first name" }],
    lastName: [{ type: "required", message: "Please enter last name" }],
    userName: [{ type: "required", message: "Please enter user name" }],
    password: [{ type: "required", message: "Please enter password" }],
    mobileNumber: [{ type: "required", message: "Please enter password" }],
    role: [{ type: "required", message: "Please select role" }],
};

const Profile = ({ user, userDetails, setUser, handleDrawer }) => {
    const navigate = useNavigate();
    const userId = userDetails?.userId;
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        mobileNumber: "",
        roleId: "",
        roleName: "",
        photo: ""
    });

    const inputRefs = useRef([
        createRef(),
        createRef(),
        createRef(),
        createRef(),
    ]);

    const handleChange = (name, value) => {
        setUserInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    useEffect(() => {
        if (userId) {
            getUserById(userId).then((res) => {
                if (res.status === 200) {
                    const {
                        firstName,
                        lastName,
                        userName,
                        roleId,
                        mobileNumber,
                        password,
                        roleName,
                        photo
                    } = res?.result?.user;
                    setUserInfo({
                        firstName,
                        lastName,
                        userName,
                        roleId,
                        mobileNumber,
                        password,
                        roleName,
                        photo
                    });
                }
            });
        }
    }, [userId]);


    const handleEdit = () => {
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (!isValid) {
            return;
        }
        editProfile(userInfo).then((res) => {
            if (res.status === 200) {
                navigate("/");
            } else {
            }
        });
    }

    return (
        <div className="h-full">
            <div className="pl-8 my-7 flex items-center md:block gap-5">
                <button className="md:hidden" onClick={handleDrawer}>
                    <i class="fa-solid fa-list"></i>
                </button>
                <p className="text-2xl leading-6 text-[#404040] font-bold">
                    Profile
                </p>
            </div>
            {/* <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between p-3 sm:p-7 border-b ">
                        <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                            Edit Profile
                        </p>
                    </div>
                    <div className="p-3 sm:p-7 sm:pt-5">
                        <div className="grid grid-cols-6 gap-5">
                            <div className="grid grid-cols-3 gap-x-6 w-full col-span-4">
                                <TextInput
                                    label="First Name"
                                    name="firstName"
                                    placeholder="Enter first name"
                                    value={userInfo.firstName}
                                    onChange={handleChange}
                                    ref={inputRefs.current[0]}
                                    validations={validations.firstName}
                                />
                                <TextInput
                                    label="Last Name"
                                    name="lastName"
                                    placeholder="Enter last name"
                                    value={userInfo.lastName}
                                    onChange={handleChange}
                                    ref={inputRefs.current[1]}
                                    validations={validations.lastName}
                                />
                                <TextInput
                                    label="User Name"
                                    name="userName"
                                    placeholder="Enter user name"
                                    value={userInfo.userName}
                                    disabled
                                />
                                <TextInput
                                    label="Password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={userInfo.password}
                                    onChange={handleChange}
                                    ref={inputRefs.current[2]}
                                    validations={validations.password}
                                />
                                <TextInput
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    placeholder="Enter user name"
                                    value={userInfo.mobileNumber}
                                    onChange={handleChange}
                                    ref={inputRefs.current[3]}
                                    validations={validations.mobileNumber}
                                />
                                <TextInput
                                    label="Role"
                                    name="roleName"
                                    value={userInfo.roleName}
                                    disabled
                                />
                            </div>
                            <div className="w-full col-span-2">
                                <div className="w-full">
                                    <ImageUpload
                                        userType="user"
                                        userId={userId}
                                        label="Click to Upload Photo"
                                        image={userInfo?.photo}
                                        onImageUpload={(imagePath, imageName) => {
                                            handleChange("photo", imagePath)
                                            setUser({ ...user, userDetails: { ...userDetails, photo: imagePath } })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-5 mr-7 pb-3">
                        <Button text="Submit" onClick={handleEdit} />
                    </div>
                </div>
            </div> */}

            <div className="bg-[#F7F8F9] sm:pt-[18px] p-5 sm:pl-7 sm:pr-11 sm:pb-9">
                <div className="bg-white w-full h-full shadow-[0px_9px_20px] shadow-[#2E235E1A] rounded-[15px]">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between p-3 sm:p-7 border-b ">
                        <p className="text-base md:text-xl leading-6 text-[#404040] font-bold">
                            Edit Profile
                        </p>
                    </div>
                    <div className="p-3 sm:p-7 sm:pt-5">
                        <div>
                            <div className="w-full col-span-2">
                                <div className="w-full">
                                    <ImageUpload
                                        // customStyle={{
                                        //     mainDiv:'justify-center',
                                        //     webCam:'w-full h-40',
                                        //     webCamImg:'w-40 h-40',
                                        //     uploadImgMainDiv:'flex justify-center',
                                        //     uploadImg:'w-40 h-40',
                                        //     uploadImgDiv:'w-40 h-40',
                                        // }}
                                        userType="user"
                                        userId={userId}
                                        label="Click to Upload Photo"
                                        image={userInfo?.photo}
                                        onImageUpload={(imagePath, imageName) => {
                                            handleChange("photo", imagePath)
                                            setUser({ ...user, userDetails: { ...userDetails, photo: imagePath } })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-6 w-full col-span-4 mt-2">
                                <TextInput
                                    label="First Name"
                                    name="firstName"
                                    placeholder="Enter first name"
                                    value={userInfo.firstName}
                                    onChange={handleChange}
                                    ref={inputRefs.current[0]}
                                    validations={validations.firstName}
                                />
                                <TextInput
                                    label="Last Name"
                                    name="lastName"
                                    placeholder="Enter last name"
                                    value={userInfo.lastName}
                                    onChange={handleChange}
                                    ref={inputRefs.current[1]}
                                    validations={validations.lastName}
                                />
                                <TextInput
                                    label="User Name"
                                    name="userName"
                                    placeholder="Enter user name"
                                    value={userInfo.userName}
                                    disabled
                                />
                                <TextInput
                                    label="Password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={userInfo.password}
                                    onChange={handleChange}
                                    ref={inputRefs.current[2]}
                                    validations={validations.password}
                                />
                                <TextInput
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    placeholder="Enter user name"
                                    value={userInfo.mobileNumber}
                                    onChange={handleChange}
                                    ref={inputRefs.current[3]}
                                    validations={validations.mobileNumber}
                                />
                                <TextInput
                                    label="Role"
                                    name="roleName"
                                    value={userInfo.roleName}
                                    disabled
                                />
                            </div>

                            <div className="flex items-center justify-end gap-5">
                                <Button text="Submit" onClick={handleEdit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (data) => {
            dispatch(setUser(data));
        },
        handleDrawer: () => dispatch(handleDrawer()),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        userDetails: state.user.userDetails,
        userPermissions: state.user.permission,
    };
};

export default WithAuth(WithHeaderSidebar(connect(mapStateToProps, mapDispatchToProps)(Profile)));

